

const _styles = {
    container: {
        width: '80%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },

    textWrapper: {
        cursor: "pointer"
    }

}

export const CellModal = ({ data, handler }) => {

    return (
        <div style={_styles.container}>
            {
                data.value.trim().length > 12 ? (
                    <>
                        <span style={_styles.textWrapper} onClick={() => handler(data)}>
                            {data.value.split("\n")[0]}
                        </span>
                    </>
                ) : (
                    <span>
                        {data.value.split("\n")[0]}
                    </span>
                )
            }
        </div >
    );
}

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import URLDATA from "./config";
import PreAuthenticationApp from "./preAuthenticationApp/App";
import { BrowserRouter,Switch, Route } from "react-router-dom";

const server1 = new HttpLink({
  uri: URLDATA.url,
  // other link options...
});

const server2 = new HttpLink({
  uri: URLDATA.url1,
  // other link options...
});
const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === "server2",
    // the string "third-party" can be anything you want,
    // we will use it in a bit
    server2, // <= apollo will send to this if clientName is "third-party"
    server1 // <= otherwise will send to this
  ),
  // other options
  // uri: URLDATA.url,
  cache: new InMemoryCache(),
});
// useQuery(QUERY, { variables, context: { clientName: 'server2' } }) or reference
ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Switch>
          <Route path='/login' component={PreAuthenticationApp} />
          <Route path='/client-login' component={PreAuthenticationApp} />
          <Route path='/' component={App} />
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import gql from 'graphql-tag';
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import URLDATA from '../config'

const link = createUploadLink({ uri: URLDATA.url1 });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      authorization: `${token}`,
    }
  }
})

export const Client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache()
})
export const getData = `
query getDocs($id:Int){
    queryClientAllFiles(id:$id){
      id
      userId
      docName
      docType
      docPath
      createdAt
      docTypeId
    }
  }
   `
   export const uploadUserDocFiles = gql`
   mutation UploadClientDocument($id:Int,$file:Upload,$docName:String,$docTypeId:Int){
     uploadClientFile(id:$id,file:$file,docName:$docName,docTypeId:$docTypeId)
   }`;
   export const fetchFile = (param) => fetch(param, {
    method: 'get',
    mode: 'cors'
  })
  
   export const mutationQueries = (Query, Variables) => Client.mutate({
    variables: Variables,
    mutation: Query
  })

export const addDocType = gql`
   mutation dxgcf($doctype:String,$mandatory:Boolean){
     insertDocType(doctype:$doctype,mandatory:$mandatory)
}`

export const getAllDoctTypes =`
{
  getAllDocType{
    docType
    id
  }
}

` 
export const modifyingDocs = `
query queryModifiedDocInfo($docId:Int){
  queryModifiedClientInfo(docId:$docId){
    id
    docName
    docPath
    docTypeId
  }
}
`;

export const updateUserDocsInfo = gql`
mutation UpdateClientDos($id:Int,$docName:String,$docTypeId:Int,$docPath:String,$temp:String,$file:Upload,$docId:Int){
  modifyClientDocs(clientId:$id,docTypeId:$docTypeId,docName:$docName,docPath:$docPath,temp:$temp,file:$file,docId:$docId)
}

`;

export const deleteUserDocs = gql`
 mutation deleteDocs($id:Int,$docPath:String,$docName:String,$docId:Int){
  deleteClientDocs(id:$id,docPath:$docPath,docName:$docName,docId:$docId)
} `
  ;

export const DownloadFile = `
  mutation downloadFiles($docId:Int,$id:Int,$path:String,$adminId:Int,$flag:String){
    downloadClientFile(docId:$docId,id:$id,path:$path,adminId:$adminId,flag:$flag){
      fileType
      fileData
      fileName
    }
  }
  
  `;

  export const fetchMethod = (Query, Variables) => fetch(URLDATA.url1, {
    method: 'POST',
    body: JSON.stringify({
      query: Query,
      variables: Variables
    }),
    headers: {
      'Content-Type': 'application/json',
      
    },
  })
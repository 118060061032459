import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  DataGrid as DataGridElement,
  GridToolbar,
  GridOverlay,
} from "@material-ui/data-grid";
import moment from "moment";
import "../All.css";
import URLDATA from "../config";
import { getAllActiveUser } from "../query.js";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { CellModal } from "../reuseableComponents";
import { clearAllPersistedState } from "../utils/usePersistedState";
import Edit from "@material-ui/icons/Edit";
import { useHistory } from "react-router";

const StyledDataGrid = withStyles({
  root: {
    "& .probationmonth-row": {
      color: "red",
    },
  },
})(DataGridElement);

const styles = {};

function DataGrid() {
  const [columns, setColumns] = useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [popupData, setPopupData] = useState(false);
  const [filter, setFilter] = useState({});
  const [prevPage, setPrevPage] = useState(0);
  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (filter.value === undefined || filter.value === "") fetchUser();
  }, [page, pageSize]);

  useEffect(() => {
    if (filter.value !== undefined && filter.value !== "") {
      setPrevPage(page);
      setPage(0);
    } else if (prevPage !== 0) setPage(prevPage);
  }, [filter]);

  let router = useHistory();
  let gridColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      hide: false,
      renderCell: (params) => <p>{params.row.name}</p>,
    },

    {
      field: "type",
      headerName: "Designation",
      width: 199,
      hide: false,
      renderCell: (params) =>
        params.row.type ? (
          <CellModal data={params} handler={showAllData} />
        ) : (
          ""
        ),
    },
    {
      field: "projectTitle",
      headerName: "Current Project",
      width: 179,
      hide: false,
      renderCell: (params) =>
        params.row.projectTitle ? (
          <CellModal data={params} handler={showAllData} />
        ) : (
          ""
        ),
    },
    {
      field: "managers",
      headerName: "Managers",
      width: 200,
      hide: false,
      renderCell: (params) =>
        params.row.managers ? (
          <CellModal data={params} handler={showAllData} />
        ) : (
          ""
        ),
    },
    {
      field: "joiningDate",
      headerName: "Joining date",
      width: 170,
      hide: false,
      renderCell: (params) => (
        <p>
          {params.value !== null
            ? moment(params.value).format("DD-MM-YYYY")
            : ""}
        </p>
      ),
    },
    {
      field: "enddate",
      headerName: "Probation End Date",
      width: 210,
      hide: false,
      renderCell: (params) => (
        <p>{moment(params.row.enddate).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 300,
      hide: false,
      renderCell: (params) =>
        params.row.notes ? (
          <CellModal data={params} handler={showAllData} />
        ) : (
          ""
        ),
    },
    {
      disableExport: true,
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => (
        <strong>
          <Edit
            onClick={() => {
              localStorage.setItem("EditRowData", JSON.stringify(params.row));
              router.push({ pathname: "/edit-probation" });
            }}
            style={{ marginInline: "4px", cursor: "pointer" }}
          />
        </strong>
      ),
    },
  ];
  const customNoRowsOverlay = () => (
    <GridOverlay>
      <div>No Data Found</div>
    </GridOverlay>
  );

  useEffect(() => {
    localStorage.removeItem("skills");
    clearAllPersistedState();
    setColumns(gridColumns);
  }, []);

  const fetchUser = () => {
    const apiUrl = `${URLDATA.url.split('graphql?')[0]}`
    fetch(`${apiUrl}api/Userdetails/getUserListWithFilter`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        checkStatus: 5,
        offset: page,
        limit: pageSize,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        context: { clientName: "server1" },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        let newData = data.response.res;
        newData.forEach((item) => {
          item.enddate = moment(item.joiningDate).add(3, "months");
        });
        setRows(newData);
        setTotalCount(data.response.totalCount);
        setLoading(false);
      })
      .catch((er) => {
        console.log(er, "err");
      });
  };

  const handlePageChange = (param) => {
    setPage(param);
  };
  const handlePageSizeChange = async (params) => {
    setLoading(true);
    setPage(0);
    setPageSize(params);
  };

  const showAllData = (data) => {
    const { value } = data;
    popupHandler(true, value);
  };

  const popupHandler = (isOpens, data) => {
    setIsOpen(isOpens);
    setPopupData(data);
  };

  return (
    <Grid container xs={12}>
      <Dialog
        onClose={() => popupHandler(false, "")}
        open={isOpen}
        sx={{ wordWrap: "break-word" }}
      >
        <DialogContent>{popupData}</DialogContent>
      </Dialog>
      <Grid container xs={12} md={12}>
        <div className="inner-header">
          <div>
            <h4 className="page-title">Probation Employees</h4>
          </div>
          <div></div>
        </div>
      </Grid>
      <Grid
        className="grid_container_dataGrid"
        container
        xs={12}
        md={12}
        style={{ marginTop: "2px" }}
      >
        <Grid
          className="grid_Data_Grid"
          item
          xs={12}
          md={12}
          style={{ height: "90vh" }}
        >
          <StyledDataGrid
            className="insideDataGrid"
            columns={columns}
            rows={rows}
            rowsPerPageOptions={[10, 15, 20, 25, 50, 75, 100]}
            pageSize={pageSize}
            rowCount={totalCount}
            page={page}
            getRowClassName={(params) => {
              const flag = moment(new Date(params.row.enddate)).diff(
                new Date(),
                "days"
              );
              return flag < 30 && flag >= 0 && `probationmonth-row`;
            }}
            density="compact"
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: customNoRowsOverlay,
            }}
            paginationMode="server"
            onFilterModelChange={(model) => setFilter(model?.items[0])}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            componentsProps={{
              cell: {
                onMouseEnter: () => {
                  console.log("HIT");
                },
                onMouseLeave: () => {
                  console.log("HIT");
                },
              },
            }}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const ProbationEmployeesInner = () => {
  return (
    <form style={{ marginTop: "10px" }}>
      <div style={{ margin: "0% 0% 0% 2.2%", height: "550px" }}>
        <Grid container spacing={4} xs={12} md={12}>
          <DataGrid />
        </Grid>
      </div>
    </form>
  );
};
ProbationEmployeesInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(
  ProbationEmployeesInner
);
function ProbationEmployees() {
  return <CompWithStyleInner />;
}
export default ProbationEmployees;

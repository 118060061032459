import React, { useState, useEffect } from 'react';
import Slider from "./Slider";
import './style.css';
import appLogo from "./images/app-logo.png";
import companyLogo from "./images/company.png";
import LoginForm from "./loginPages/Login";
import { Redirect } from 'react-router-dom';
import { ApolloProvider } from "react-apollo";
import ApolloClient from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import URLDATA from '../config';
import { InMemoryCache } from "apollo-cache-inmemory";


const link = createUploadLink({ uri: URLDATA.url1.split('?')[0] });

const authLink = setContext((_, { _headers }) => {
    const xcv = 0;

    return {
        headers: {
            authorization: null,
            xcvtoken: `${xcv}`
        }
    }
})

export const client = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache()
})
// pre authentication app
const App = () => {
    const [isToken, setIsToken] = useState(false)

    useEffect(() => {
        checkToken()
    }, [])

    const checkToken = () => {
        const _isToken = localStorage.getItem("token")
        if (_isToken == null || _isToken == 'undefined') {
            setIsToken(false)
        } else {
            setIsToken(true)
        }
    }


    return (
        <>
            {
                isToken ?
                    <Redirect to="/" /> :
                    <ApolloProvider client={client}>
                        <div id="preLogin-container">
                            <div id="leftBox">
                                <Slider />
                            </div>
                            <div id="rightBox">
                                <div id="loginPageTitle">
                                    <img src={appLogo} alt="" />
                                    <h4 >Goal Plan System</h4>
                                    <span>by QSS</span>
                                </div>
                                <LoginForm checkToken={checkToken} />
                                <div className="cmp-logo">
                                    <p className="powered">Powered By</p>
                                    <img src={companyLogo} alt="logo" />
                                </div>
                            </div>
                        </div>
                    </ApolloProvider>
            }
        </>
    );
};

export default App;




import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/client";
import { useHistory, Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { editingProbationUser } from "../query.js";
import "../All.css";

const styles = makeStyles(() => ({}));
//Class Designer :
//Components

const AddEditProbationInner = () => {
  const [fields, setFields] = useState({ name: "" });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formProcessing, setFormProcessing] = useState(false);

  let router = useHistory();

  useEffect(() => {
    if (localStorage.getItem("EditRowData")) {
      setFields(JSON.parse(localStorage.getItem("EditRowData")));
    }
  }, []);

  const handleDialogClose = () => {
    setDialogOpen(false);
    router.push({ pathname: "/probation" });

    setFormProcessing(false);
  };
  function submitCompleted() {
    setFormProcessing(true);
    setDialogOpen(true);
  }

  const [saveMutation, {loading}] =
    useMutation(editingProbationUser, {
      variables: {
        notes: fields.notes,
        probation: fields.probation,
        id: fields.id,
      },
      onCompleted: submitCompleted,
      context: { clientName: "server2" },
      onError: (err) => {
        console.log(err, "ERR");
      },
    });

  const handleChange = (field, e) => {
    if (field === "probation")
      setFields((prev) => {
        return { ...prev, [`${field}`]: +e.target.value };
      });
    else
      setFields((prev) => {
        return { ...prev, [`${field}`]: e.target.value };
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveMutation({
      variables: {
        notes: fields.notes,
        probation: fields.probation,
        id: fields.id,
      },
    });
  };

  return (
    <>
      <Grid container xs={12} md={12} spacing={3}>
        <div className="inner-header">
          <div className="margin-auto" style={{ margin: "0px 38% auto" }}>
            <h4 className="page-title">Edit Notes</h4>
          </div>
          <div>
            <Link className="navNames" to="/probation">
              <Button color="primary" variant="outlined">
                {" "}
                Back{" "}
              </Button>
            </Link>
          </div>
        </div>
      </Grid>
      <form>
        <div style={{ margin: "2%" }}>
          <Grid container spacing={4}>
            <Grid container item xs={12} spacing={4}>
              <div
                style={{
                  marginTop: "100px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "30%", marginRight: "50px" }}>
                  <TextField
                    variant="outlined"
                    id="notes"
                    style={{ width: "100%" }}
                    label="Notes"
                    type="text"
                    multiline
                    rows={5}
                    value={fields.notes}
                    onChange={(e) => {
                      handleChange("notes", e);
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: false,
                    }}
                  />
                </div>
                <div style={{ width: "30%" }}>
                  <FormControl style={{ width: "100%", marginTop: "9px" }}>
                    <InputLabel id="demo-simple-select-label">
                      On Probation
                    </InputLabel>
                    <Select
                      native
                      onChange={(e) => {
                        handleChange("probation", e);
                      }}
                    >
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </Grid>

            <Grid item xs={10} style={{ marginTop: "100px" }}>
              <Button
                color="primary"
                style={{ float: "right" }}
                variant="contained"
                disabled={loading || formProcessing}
                onClick={(e) => {
                  handleSubmit(e);
                }}
                id="update"
              >
                {(loading || formProcessing) && (
                  <span style={{ color: "white" }}>
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Please Wait...
                  </span>
                )}
                {!(loading || formProcessing) && <span>Update</span>}
              </Button>

              <Dialog
              id="probationdialog"
                open={dialogOpen}
                onClose={handleDialogClose}
                disableEscapeKeyDown={true}
                aria-describedby="alert-dialog-description"
              >
                <DialogContent id="probationcontent">
                  <div style={{ width: "300px" }}>
                    <DialogContentText id="alert-dialog-description">
                      {"Success!!"}
                    </DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions id="probationactions">
                  <Button onClick={handleDialogClose} color="primary" autoFocus>
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
};
AddEditProbationInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(
  AddEditProbationInner
);
function AddEditProbation() {
  return <CompWithStyleInner />;
}
export default AddEditProbation;

import React from 'react';
import URLDATA from "../../../config"

class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: "",
            persistStateId: null,
            userData: undefined
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = e => {
        e.preventDefault();
        const requestHeader = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: e.target[0].value.trim(), password: e.target[1].value })
        };

        const baseUrl = `${URLDATA.url.split('graphql?')[0]}login`

        fetch(baseUrl, requestHeader)
            .then(response => {
                if (response.status === 500 || response.status === 400) {
                    return response.text().then((text) => {
                        const errorText = JSON.parse(text).message
                        throw new Error(errorText)
                    })
                }
                return response.json()
            })
            .then(data => {
                if (data.error) {
                    throw new Error(data.message)
                }
                localStorage.setItem("token", data.token);
                localStorage.setItem('id', data.id);
                localStorage.setItem('permissions', JSON.stringify(data.permissions));
                this.props.checkToken()
                
            }).catch(err => {
                console.log(err, 'errr');
                this.setState({ errors: err.message })
            });


        e.target[0].value = "";
        e.target[1].value = "";


    }


    render() {

        return (
            <form onSubmit={this.onSubmit} >
                <div id="login-form">
                    <h6 className="text-center loginForm-title">login to your account</h6>
                    <div className="form-group marginBottom fullSize">
                        <input
                            placeholder="Enter email"
                            className="form-control"
                            type="email"
                            name="email"
                            id="email"
                            required
                        />
                    </div>

                    <div className="fullSize marginBottom form-group">
                        <input
                            placeholder="Enter password"
                            className="form-control"
                            type="password"
                            name="password"
                            id="password"
                            required
                        />
                    </div>

                    <button className="submit-button" type="submit">Continue</button>
                    <p
                        style={{
                            marginBottom: "0",
                            marginTop: "8px",
                            textAlign: "center",
                            color: "red"
                        }}
                        id="error"
                    >
                        {this.state.errors}
                    </p>
                </div>
            </form>
        );
    }
}



export default LoginForm;

import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useHistory, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { gql, useMutation } from "@apollo/client";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dropzone from "react-dropzone";
import "./questions.css";
import URLDATA, { authHeader } from "../config";

const styles = () => ({});

const complexityOptions = {
  easy: "Easy",
  beginner: "Beginner",
  intermediate: "Intermediate",
  advance: "Advance",
};

const QuestionsInner = () => {
  const [fields, setFields] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    correctanswer: "",
  });
  const [errors, setErrors] = useState();
  const [success, setSuccess] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [file, setFile] = useState(false);
  const [formProcessing, setFormProcessing] = useState(false);
  const [skillsData, setSkillsData] = useState("");
  const [skillsDropData, setSkillsDropData] = useState("");
  const [listData, setListData] = useState([]);
  const [URL, setURL] = useState("");

  useEffect(() => {
    fetch(URLDATA.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
        xcvtoken: localStorage.getItem("id"),
      },
      body: JSON.stringify({
        query: `
          query {
            allSkills{
              Skills {
                id
                name
              }
            }
          }`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const data = res?.data?.allSkills?.Skills;
        const newData = {};
        const newDropData = {};
        data.forEach((skill) => {
          const key = skill.name.toLowerCase().trim();
          newData[`${key}`] = skill.id;
          newDropData[`${skill.id}`] = skill.name;
        });
        setSkillsData(newData);
        setSkillsDropData(newDropData);
      });
  }, []);

  useEffect(() => {
    if (fields.type) {
      const url = URLDATA.url.split("graphql")[0];
      setURL(`${url}template?type=${fields.type}`);
    }
  }, [fields]);

  const typeOptions = [
    {
      value: "MCQ",
      text: "MCQ",
      key: "1",
    },
    {
      value: "TEXT",
      text: "TEXT",
      key: "2",
    },
  ];

  const gqlMutation = (function () {
    let code = ` 
      mutation($id:Float,$complexity:String,$correctanswer:String,$createdat:Date!,$option1:String,$option2:String,$option3:String,$option4:String,$question:String, $skill:String, $skillId:String,$type:String,$updatedat:Date!,$duration:Float) {
        saveQuestions(obj: {id:$id,complexity: $complexity, corretanswer: $correctanswer, createdat: $createdat, option1: $option1, option2: $option2, option3: $option3, option4: $option4, question: $question, skill: $skill, skillId: $skillId, type: $type, updatedat: $updatedat,duration:$duration}) {
            id
          }
          }`;
    return gql`
      ${code}
    `;
  })();

  const [saveMutation, { loading }] = useMutation(gqlMutation, {
    variables: {
      type: fields.type,
      createdat: new Date(),
      updatedat: new Date(),
    },
    onCompleted: submitCompleted,
    context: authHeader(),
  });

  let router = useHistory();

  const handleDialogClose = () => {
    setDialogOpen(false);
    router.push("/test-questions");
    setFormProcessing(false);
  };
  function submitCompleted() {
    setFormProcessing(true);
  }

  const getFlag = (labels) =>{
    if(fields.type === "MCQ" && labels.length >= 8){
      return true
    }
    else{
      if(fields.type === "TEXT" && labels.length <= 4){
        return true
      }
    }
    return false
  }

  const fileUpload = ([File]) => {
    if (fields.type) {
      if (File.type === "text/csv") {
        setFile(true);
        setErrors("");
        setSuccess("List uploaded");
        setListData([]);
        const reader = new FileReader();
        reader.onload = (event) => {
          let data = event.target.result.split("\r\n");
          let labels = data.splice(0, 1);
          labels = labels[0].split(",");
          const Flag = getFlag(labels)
          if (Flag) {
            data = data.map((question) => {
              const values = question.split(",");
              const newdata = {};
              values.map((field, index) => {
                newdata[`${labels[index]}`] = field;
              });
              return newdata;
            });
            if (
              !(
                data[`${data.length - 1}`] &&
                data[`${data.length - 1}`].duration
              )
            )
              data.splice(data.length - 1);
            setListData(data);
          } else setErrors("Template and Type are different..");
        };
        reader.readAsText(File);
      } else {
        setFile(false);
        setSuccess("");
        setErrors("Please upload a valid csv file..");
      }
    } else setErrors("Please select type..");
  };

  const handleChange = (field, value) => {
    setErrors("");
    setFields((prev) => {
      return { ...prev, [`${field}`]: value };
    });
  };
  const makePost = (ques) => {
    let fieldsT;
    if (fields.type === "MCQ") fieldsT = { ...ques, type: fields.type };
    else fieldsT = { ...ques, ...fields };

    return saveMutation({
      variables: {
        ...fieldsT,
        duration: parseFloat(+ques.duration * 60000),
        skill: ques.skill.split(";").join(","),
      },
    });
  };

  const validator = (ques) => {
    ques.complexity =
      complexityOptions[`${ques.complexity.toLowerCase()}`] || "";
    const data = { ...ques };
    delete data.correctanswer;
    const emptyFields = Object.values(data).some((value) => value === "");
    return emptyFields ? false : true;
  };

  const getSkillIds = (ques) => {
    console.log(ques);
    let skills = ques.skill !== "" ? ques.skill.split(";") : [];
    ques.skill = [];
    skills = skills.map((item) => {
      let id = null;
      Object.keys(skillsData).forEach((skilll) => {
        if (item.toLowerCase() === skilll) {
          id = skillsData[`${item.toLowerCase()}`];
          ques.skill.push(skillsDropData[id]);
          return id;
        }
      });
      if (id !== null) return id;
      else return null;
    });
    ques.skill = ques.skill.join(",");
    skills = skills.filter((item) => item !== null);
    return skills.join(",");
  };

  const handleClear = () => {
    setErrors("");
    setListData([]);
    setSuccess("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fields.type === "MCQ" || fields.type === "TEXT") {
      if (file) {
        if (listData.length > 0) {
          let failedRows = [];
          let responseList = [];
          listData.forEach((ques, index) => {
            ques.skillId = getSkillIds(ques);
            if (validator(ques)) {
              responseList.push(makePost(ques));
            } else failedRows.push(index + 2);
          });
          responseList = await Promise.allSettled(responseList);
          const newRows = responseList
            .map((item, index) => {
              if (!item?.value?.data?.saveQuestions?.id) return index;
              return null;
            })
            .filter((item) => item !== null);
          failedRows = [...failedRows, ...newRows];
          if (failedRows.length > 0) {
            setErrors(
              `Required fields (Missing/Incorrect) in question ${failedRows.join(
                ","
              )} ..`
            );
            setFormProcessing(false);
          } else setDialogOpen(true);
        } else setErrors("Uploaded file is empty..");
      } else setErrors("Please select a file to upload..");
    } else {
      if (fields.type)
        setErrors("Currently only MCQ and TEXT questions can be imported..");
      else setErrors("Please select type..");
    }
  };

  useEffect(() => {
    setSuccess("");
    setErrors("");
    setListData([]);
  }, [fields]);

  return (
    <>
      <Grid container xs={12} md={12} spacing={3}>
        <div className="inner-header">
          <div className="margin-auto" style={{ margin: "0px 38% auto" }}>
            <h4 className="page-title">Import Questions</h4>
          </div>
          <div>
            <Link className="navNames" to="/test-questions">
              <Button color="primary" variant="outlined">
                {" "}
                Back{" "}
              </Button>
            </Link>
          </div>
        </div>
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        disableEscapeKeyDown={true}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ width: "300px" }}>
            <DialogContentText id="alert-dialog-description">
              {"Success!!"}
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <div className="mainSection">
        <div className="contentsection">
          <div className="typediv">
            <Autocomplete
              style={{ padding: "1rem" }}
              options={typeOptions}
              getOptionLabel={(option) => (option.text ? option.text : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  variant="outlined"
                  className="inputWidth"
                  id="type"
                  label="Type"
                />
              )}
              onChange={(_value, event) => {
                handleChange("type", event ? event.value : undefined);
              }}
            />
          </div>
          <div className="dropdiv">
            <div className="successdiv">
              <p>{success}</p>
            </div>
            <Dropzone onDrop={fileUpload}>
              {({ getRootProps, getInputProps }) => (
                <section
                  className="dropcontainer"
                  onClick={() => {
                    setSuccess("");
                  }}
                >
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <svg
                      className="mb-3 w-10 h-10 text-gray-200"
                      fill="none"
                      stroke="gray"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p>Click to upload</p>
                  </div>
                </section>
              )}
            </Dropzone>
            <div className="template">
              {fields.type ? (
                <>
                  <a href={URL} target="blank">
                    Download Template
                  </a>
                  <span className="Note">
                    (Note: Please use " <strong>;</strong> " for multiple
                    skills)
                  </span>
                </>
              ) : (
                ""
              )}
              <div className="errordiv">
                <p>{errors}</p>
              </div>
            </div>
            <div className="submitdiv">
              <div className="btnsubmit">
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ width: "70%" }}
                    disabled={loading || formProcessing}
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    id="saveButton"
                  >
                    {(loading || formProcessing) && (
                      <span style={{ color: "white" }}>
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />{" "}
                        Please Wait...
                      </span>
                    )}
                    {!(loading || formProcessing) && <span>Submit</span>}
                  </Button>
                </div>
                <div>
                  <Button
                    color="error"
                    variant="contained"
                    style={{ width: "70%" }}
                    onClick={handleClear}
                  >
                    <span>Clear</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(
  QuestionsInner
);
function ImportQuestions() {
  return <CompWithStyleInner />;
}
export default ImportQuestions;

import userReports from "./useravailabality/userReport";
import AddTest from "./tests/AddTest";
import Tests from "./tests/TestList";
import TestQuestions from "./questions/Questionslist";
import Questions from "./questions/Questions";
import VendorList from "./Vendors/vendorList";
import AddVendor from "./Vendors/AddVendor";
import Job from "./jobDescription/Job";
import College from "./college/College";
import ProbationEmployees from "./probation/ProbationEmployees";
import AddEditCollege from "./college/AddEditCollege";
import AddEditJobs from "./jobDescription/AddEditJobs";
import Customer from "./customer/Customer";
import CustomerDetails from "./customer/CustomerDetails";
import AddEditCustomer from "./customer/AddEditCustomer";
import Resignation from "./resignation/resignationList";
import AddResignationNotes from "./resignation/AddEditResignation";
import AddProbationNotes from "./probation/AddEditProbation";
import ImportQuestions from "./questions/importQuestions";
import UserNotes from "./useravailabality/editUserNotes";
// import ClientProjects from './clients/projectList'
// import Dashboard from './clients/dashboard';
// Module name should be same on the both sheets SIDEBARLIST and ROUTES

const allRoutes = [
  // {
  //     path: '/',
  //     component: ClientProjects,
  //     moduleName: 'client'
  // },

  // {
  //     path: '/project-updates',
  //     component: Dashboard,
  //     moduleName: 'client'
  // },

  {
    path: "/",
    component: userReports,
    moduleName: "userAvailability",
  },

  {
    path: "/edit-userAvailability",
    component: UserNotes,
    moduleName: "userAvailability",
  },

  {
    path: "/add-test",
    component: AddTest,
    moduleName: "managetests",
  },

  {
    path: "/edit-test",
    component: AddTest,
    moduleName: "managetests",
  },

  {
    path: "/tests",
    component: Tests,
    moduleName: "managetests",
  },

  {
    path: "/test-questions",
    component: TestQuestions,
    moduleName: "manageQuestion",
  },

  {
    path: "/probation",
    component: ProbationEmployees,
    moduleName: "probation",
  },

  {
    path: "/add-question",
    component: Questions,
    moduleName: "manageQuestion",
  },

  {
    path: "/import-question",
    component: ImportQuestions,
    moduleName: "manageQuestion",
  },

  {
    path: "/edit-question",
    component: Questions,
    moduleName: "manageQuestion",
  },

  {
    path: "/vendors",
    component: VendorList,
    moduleName: "vendors",
  },

  {
    path: "/add-vendor",
    component: AddVendor,
    moduleName: "vendors",
  },

  {
    path: "/edit-vendor",
    component: AddVendor,
    moduleName: "vendors",
  },

  {
    path: "/jobs",
    component: Job,
    moduleName: "job",
  },

  {
    path: "/add-jobs",
    component: AddEditJobs,
    moduleName: "job",
  },

  {
    path: "/edit-jobs",
    component: AddEditJobs,
    moduleName: "job",
  },

  {
    path: "/college",
    component: College,
    moduleName: "college",
  },

  {
    path: "/add-college",
    component: AddEditCollege,
    moduleName: "college",
  },

  {
    path: "/edit-college",
    component: AddEditCollege,
    moduleName: "college",
  },
  {
    path: "/resignation-list",
    component: Resignation,
    moduleName: "resignation",
  },
  {
    path: "/edit-resignation",
    component: AddResignationNotes,
    moduleName: "resignation",
  },
  {
    path: "/edit-probation",
    component: AddProbationNotes,
    moduleName: "probation",
  },
  {
    path: "/add-resignation",
    component: AddResignationNotes,
    moduleName: "resignation",
  },

  {
    path: "/customer",
    component: Customer,
    moduleName: "customer",
  },
  {
    path: "/customer-details",
    component: CustomerDetails,
    moduleName: "customer",
  },
  {
    path: "/add-customer",
    component: AddEditCustomer,
    moduleName: "customer",
  },

  {
    path: "/edit-customer",
    component: AddEditCustomer,
    moduleName: "customer",
  },
];
function routes(){
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  if (permissions !== null) {
    let newList = allRoutes.filter(
      (item) => permissions[`${item.moduleName}`] === 1
    );
    newList.forEach((route) => (route.permission = 1));
    return newList;
  }
}
export default routes;

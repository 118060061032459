import React, { useEffect, useState } from "react";
import { Link,  useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import "./sideBar.css";
import URLDATA from "./config";
import swal from "sweetalert";
import listWithPermission from "./sideBarList";

const _styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: "2rem",
    height: "100%",
    cursor: "pointer",
    // cursor: "cell",
  },

  logoutBtn: {
    display: "flex",
    alignItem: "center",
    cursor: "pointer",
  },
};

export default function SideBar() {
  const [state, setState] = useState({ left: false });
  const [navList, setNavList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const _navList = listWithPermission();
    setNavList(_navList);
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const loggingOut = () => {
    const requestHeader = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
        // xcvtoken: localStorage.getItem("id"),
      },
    };

    const baseUrl = `${URLDATA.url.split("graphql?")[0]}logout`;

    fetch(baseUrl, requestHeader)
      .then((response) => {
        if (response.status === 500 || response.status === 400) {
          return response.text().then((text) => {
            const errorText = JSON.parse(text).message;
            throw new Error(errorText);
          });
        }
        return response.json();
      })
      .then((data) => {
        console.log(data, "data");
      })
      .catch((err) => {
        console.log(err.message);
        // swal(err.message)
      });
    localStorage.clear();
    history.push("/login");
  };

  const logoutHandler = () => {
    swal({
      title: "Are you sure you want to logout?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((res) => {
        if (res) loggingOut();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const list = (anchor, navList1) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 195 }}
      style={_styles.wrapper}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {navList1.map((item, i) => {
            return (
              <Link key={i} className="navNames" to={item.link}>
                <ListItem button style={{ display: "block" }} key={i}>
                  <div className="menuItem">
                    <p className="menuIcon">{item.icon}</p>
                    <p>{item.label}</p>
                  </div>
                </ListItem>
              </Link>
            );
          }
        )}
      </List>

      <div style={_styles.logoutBtn} onClick={logoutHandler}>
        <LogoutIcon />
        <span className="navNames">Logout</span>
      </div>
    </Box>
  );

  return (
    <div className="sidebar">
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <MenuIcon
            style={{ margin: "0% 0% 0% .5%", cursor: "pointer" }}
            onClick={toggleDrawer(anchor, true)}
          >
            {anchor}
          </MenuIcon>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {navList && navList.length !== undefined &&
              navList.length > 0 &&
              list(anchor, navList)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

import React, {
  useEffect,
  useState
} from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { gql, useMutation} from "@apollo/client";
import { useHistory,Link } from "react-router-dom";
import Rating from '@mui/material/Rating';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import "../All.css";
import { authHeader } from "../config";
import { IsValidEmail, isValidPhoneNumber} from "../utils/Validation";

const styles = makeStyles(() => ({}));
//Class Designer :
//Components

const AddVendorsInner = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formProcessing, setFormProcessing] = useState(false);

  let router = useHistory();


  const handleDialogClose = () => {
    setDialogOpen(false);
      router.push({ pathname: "/vendors" });
    setFormProcessing(false);
  };
  function submitCompleted() {
    setFormProcessing(true);
    setDialogOpen(true);
  }

  const gqlMutation5 = (function () {
    let code = `mutation (
    $id: Float
    $active: Float
    $address: String
    $contactnumber: String
    $contactperson: String
    $createdon: Date!
    $email: String
    $experties: String
    $name: String
    $numberofemployees: Float
    $rating: Float
    $othercontacts: String
  ) {
    saveVendors(
      obj: {
        id: $id
        active: $active
        address: $address
        contactnumber: $contactnumber
        contactperson: $contactperson
        createdon: $createdon
        email: $email
        experties: $experties
        name: $name
        numberofemployees: $numberofemployees
        rating: $rating
        othercontacts: $othercontacts
      }
    ) {
      id
      active
      address
      contactnumber
      contactperson
      createdon
      email
      experties
      name
      numberofemployees
      rating
      othercontacts
    }
  }`;
    return gql`
        ${code}
      `;
  })();

  const [saveMutation5, {loading}] =
    useMutation(gqlMutation5,
      { 
        variables: {
          name: fields.name,
          email: fields.email,
          address: fields.address,
          contactnumber: fields.contactnumber,
          contactperson: fields.contactperson,
          numberofemployees: parseInt(fields.numberofemployees),
          experties: fields.experties,
          skillId: fields.skillId,
          othercontacts: fields.othercontacts,
          rating: fields.rating,
          createdon: new Date(),
          id: fields.id
        },
        onCompleted: submitCompleted,
        context: authHeader()
      });
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("EditRowData"));

    if (data) {
      Object.entries(data).forEach(() => {
        setFields({
          id: data.id,
          name: data.name,
          email: data.email,
          address: data.address,
          contactnumber: data.contactnumber,
          contactperson: data.contactperson,
          experties: data.experties,
          numberofemployees: data.numberofemployees,
          othercontacts: data.othercontacts,
          rating: data.rating
        });
      });
    }
  }, []);

  const makePost = () => {
    let fieldsT = {};
    let data = JSON.parse(localStorage.getItem("EditRowData"));
    if (data) {
      Object.assign(fieldsT, fields);
      saveMutation5({
        variables: {
          id: fieldsT.id,
          name: fieldsT.name,
          email: fieldsT.email,
          address: fieldsT.address,
          contactnumber: fieldsT.contactnumber,
          contactperson: fieldsT.contactperson,
          numberofemployees: parseInt(fieldsT.numberofemployees),
          experties: fieldsT.experties,
          skillId: fieldsT.skillId,
          othercontacts: fieldsT.othercontacts,
          rating:fieldsT.rating,
          createdon: new Date(),
        },
      });
    } else
      saveMutation5({
        variables: {
          input: fieldsT,
        },
      });
  };

  const handleValidation7 = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid7 = true;
    let fieldValue = e && e.target ? e.target.value : e;
    let updatedValue = fieldValue;
    
    //name validations
    if (fieldname === undefined || fieldname === "name") {
      if (fieldname === undefined) {
        fieldValue = fields["name"] ? fields["name"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["name"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid7 = false;
        errorsT["name"] = "Name is required";
      }
    }

    //email validations
    if (
      fieldname === undefined ||
      fieldname === "email"
    ) {
      if (fieldname === undefined) {
        fieldValue = fields["email"]
          ? fields["email"]
          : "";
        updatedValue = fieldValue;
      }

      delete errorsT["email"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid7 = false;
        errorsT["email"] = "Email is required";
      } else if (!IsValidEmail(updatedValue)) {
        formIsValid7 = false;
        errorsT["email"] =
          "Please enter Email in correct format";
      }
    }

    //contactNumber validations
    if (fieldname === undefined || fieldname === "contactnumber") {
      if (fieldname === undefined) {
        fieldValue = fields["contactnumber"] ? fields["contactnumber"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["contactnumber"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid7 = false;
        errorsT["contactnumber"] = "Contact Number is required";
      } else if (!isValidPhoneNumber(updatedValue)) {
        formIsValid7 = false;
        errorsT["contactnumber"] =
          "Please enter Phone in correct format";
      }
    }

    //contactPerson validations
    if (fieldname === undefined || fieldname === "contactperson") {
      if (fieldname === undefined) {
        fieldValue = fields["contactperson"] ? fields["contactperson"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["contactperson"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid7 = false;
        errorsT["contactperson"] = "Contact Person is required";
      }
    }

    setErrors({ ...errorsT });
    return { formIsValid7, updatedValue };
  };

  const handleChange = (field, e) => {
    const { updatedValue } = handleValidation7(field, e);
    let fieldsT = fields;
    fieldsT[field] = updatedValue;
    setFields({ ...fieldsT });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const { formIsValid7 } = handleValidation7();
    if (formIsValid7) {
      makePost();
    }
  };
  return (
    <>
      <Grid container xs={12} md={12} spacing={3}>
        <div className="inner-header">
          <div className="margin-auto" style={{ margin: "0px 38% auto" }}>
            <h4 className="page-title">
              {JSON.parse(localStorage.getItem("EditRowData")) ? 'Edit' : 'Add'}  Vendors
            </h4>
          </div>
          <div>
            <Link className="navNames" to="/vendors">
              <Button color="primary" variant="outlined"> Back </Button>
            </Link>
          </div>
        </div>
      </Grid>
      <form id="vendorform">

        <div style={{ margin: "2%" }}>

          <Grid container spacing={2} >

            <Grid container item xs={6} direction="column">
              <TextField variant="outlined"
                id='name_vendor'
                label='Name'
                type='text'
                value={fields.name ? fields.name : ''}
                onChange={(eve) => { handleChange("name", eve) }}
                onBlur={(eve) => { handleChange("name", eve) }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                required
              /><div style={{ color: "red" }}>{errors["name"]}</div>
            </Grid>

            <Grid container item xs={6} direction="column">
              <TextField variant="outlined"
                id='email'
                label='Email'
                type='text'
                value={fields.email || ''}
                onChange={(event) => { handleChange("email", event) }}
                onBlur={(event) => { handleChange("email", event) }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                required
              />
              <div style={{ color: "red" }}>{errors["email"]}</div>
            </Grid>

            <Grid container item xs={6} direction="column">
              <TextField variant="outlined"
                id='contactnumber'
                label='Contact Number'
                type='number'
                value={fields.contactnumber || ''}
                onChange={(events) => { handleChange("contactnumber", events) }}
                onBlur={(events) => { handleChange("contactnumber", events) }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                required
              /><div style={{ color: "red" }}>{errors["contactnumber"]}</div>
            </Grid>

            <Grid container item xs={6} direction="column">
              <TextField variant="outlined"
                id='contactperson'
                label='Contact Person'
                type='text'
                value={fields.contactperson || ''}
                onChange={(event) => { handleChange("contactperson", event) }}
                onBlur={(event) => { handleChange("contactperson", event) }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                required
              /><div style={{ color: "red" }}>{errors["contactperson"]}</div>
            </Grid>

            <Grid container item xs={6} direction="column">
              <TextField variant="outlined"
                id='address'
                label='Address'
                type='text'
                value={fields.address || ''}
                onChange={(event) => { handleChange("address", event) }}
                onBlur={(event) => { handleChange("address", event) }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
              // required
              />
              {/* <div style={{ color: "red" }}>{errors["address"]}</div> */}
            </Grid>

            <Grid container item xs={6} direction="column">
              <TextField variant="outlined"
                id='numberofemployees'
                label='Number Of Employees'
                type='text'
                value={fields.numberofemployees || ''}
                onChange={(event) => { handleChange("numberofemployees", event) }}
                onBlur={(event) => { handleChange("numberofemployees", event) }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
              // required
              />
              {/* <div style={{ color: "red" }}>{errors["numberofemployees"]}</div> */}
            </Grid>

            <Grid container item xs={6} direction="column">
              <TextField variant="outlined"
                id='experties'
                label='Experties'
                type='text'
                value={fields.experties || ''}
                onChange={(event) => { handleChange("experties", event) }}
                onBlur={(event) => { handleChange("experties", event) }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
              // required
              />
              {/* <div style={{ color: "red" }}>{errors["experties"]}</div> */}
            </Grid>

            <Grid container item xs={6} direction="column">
              <TextField variant="outlined"
                id='othercontacts'
                label='Other Contacts'
                type='text'
                value={fields.othercontacts || ''}
                onChange={(event) => { handleChange("othercontacts", event) }}
                onBlur={(event) => { handleChange("othercontacts", event) }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
              // required
              />
              {/* <div style={{ color: "red" }}>{errors["othercontacts"]}</div> */}
            </Grid>
            <Grid container item xs={6} direction="column">
              <TextField variant="outlined"
                sx={{
                  ".MuiOutlinedInput-root": {
                    paddingTop: "1rem",
                    flexDirection: "column"
                  },
                  Rating: {
                    paddingRight: "1rem"
                  }
                }}
                InputProps={{
                  startAdornment: <Rating
                                    name="simple-controlled"
                                    value={fields.rating || 0}
                                    onChange={(_, newValue) => {
                                      if(!newValue)
                                        newValue = fields.rating-1;
                                      handleChange("rating", newValue);
                                    }
                                   }
                                  />,
                  readOnly: true,

                           }}
                id='rating'
                label='Rating'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} id="venordiv">
              <Button color="primary" variant="contained" disabled={(loading || formProcessing)} onClick={(e) => { handleSubmit(e) }}
                id='updatevendor' >
                {(loading || formProcessing) && (
                  <span style={{ color: "white" }}>
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    /> Please Wait...
                  </span>
                )}
                {!(loading || formProcessing) && <span>{localStorage.getItem('EditRowData') ? "Update" : "Submit"}</span>}
              </Button>
              <Dialog
              id="vendordialog"
                open={dialogOpen}
                onClose={handleDialogClose} disableEscapeKeyDown={true}
                aria-describedby="alert-dialog-description">
                <DialogContent id="vendorcontent">
                  <div style={{ width: "300px" }}>
                    <DialogContentText id="alert-dialog-description">
                      {"Success!!"}
                    </DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions id="vendoractions">
                  <Button onClick={handleDialogClose} color="primary" autoFocus>
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
};
AddVendorsInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(
  AddVendorsInner
);
function AddVendor() {
  return <CompWithStyleInner />;
}
export default AddVendor;
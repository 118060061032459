import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { gql, useMutation } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {
  DataGrid as DataGridElement,
  GridToolbar,
} from "@material-ui/data-grid";
import Edit from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import swal from "sweetalert";
import Button from "@mui/material/Button";
import URLDATA, { authHeader } from "../config";
import { useHistory } from "react-router";
import { getAllJobs } from "../query.js";
import {
  usePersistedState,
  clearOnReloadPage,
} from "../utils/usePersistedState";
import { customNoRowsOverlay } from "../utils/overlay";
const styles = () => ({});

const DataGrid = (props) => {
  const [columns, setColumns] = useState([]);
  const [page, setPage] = usePersistedState("Page", 0, "job");
  const [pageSize, setPageSize] = usePersistedState("PageSize", 10, "job");
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [prevPage, setPrevPage] = useState(0);

  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };
  useEffect(() => {
    if (filters.value !== undefined && filters.value !== "") {
      setPrevPage(page);
      setPage(0);
    } else if (prevPage !== 0) setPage(prevPage);
  }, [filters]);

  const fetchJobList = () => {
    fetch(URLDATA.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
        xcvtoken: localStorage.getItem("id"),
      },
      body: JSON.stringify({
        query: getAllJobs,
        variables: {},
        context: { clientName: "server1" },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.removeItem("EditRowData");
        const _row = res.data.allJobdescriptions.Jobdescriptions.filter(
          (data) => data.active == 1
        );
        const finalRow = _row.map((item) => {
          item.description = stripHtml(item.description);
          return item;
        });
        setRows(finalRow);
        setTotalCount(
          res.data.allJobdescriptions.Jobdescriptions
            ? res.data.allJobdescriptions.Jobdescriptions?.filter(
                (data) => data.active === 1
              ).length
            : 1000
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    setPage(0);
  }, [pageSize]);

  useEffect(() => {
    setColumns(gridcolumns);
    clearOnReloadPage();
    localStorage.removeItem("EditRowData");
    localStorage.setItem("RowPage", "job");
    fetchJobList();
  }, []);

  const handlePageChanger = (param) => {
    setPage(param);
  };
  const handlePageSizeChanger = async (params) => {
    setPageSize(params);
  };

  let router = useHistory();
  let gridcolumns = [
    { field: "title", headerName: "Title", width: 200 },
    { field: "technologies", headerName: "Technologies", width: 250 },

    { field: "description", headerName: "Description", width: 300 },
    { field: "internalnotes", headerName: "Internal Notes", width: 300 },
    { field: "screeningtesturl", headerName: "Screening Test URL", width: 300 },

    { field: "action", headerName: "Actions", width: 100 },
  ].filter((item) => {
    return item.field !== "action";
  });

  gridcolumns = [
    ...gridcolumns,
    {
      disableExport: true,
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <strong>
          <Edit
            onClick={() => {
              localStorage.setItem(
                "EditRowData",
                JSON.stringify(params.row)
                // params.row.skill: [params.row.skill]
              );
              router.push({ pathname: "/edit-jobs" });
            }}
            style={{ marginInline: "4px", cursor: "pointer" }}
          />

          <TrashIcon
            onClick={
              // handleClickOpen
              () => {
                let uniqueRowData = params.row;
                swal({
                  title: "Are you sure?",
                  // text: "Once deleted, you will not be able to recover this imaginary file!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    props.deleteHandler({
                      ...uniqueRowData,
                      id: params.row.id,
                    });
                    //   getUpdatedList()
                    swal("Job has been deleted!", {
                      icon: "success",
                    });
                    fetchJobList();
                  }
                });
              }
            }
            style={{ marginInline: "4px", cursor: "pointer" }}
          />
        </strong>
      ),
    },
  ];

  return (
    <>
      <Grid container xs={12} style={{ height: "85vh", marginTop: "2rem" }}>
        <DataGridElement
          columns={columns}
          rows={rows}
          rowCount={totalCount}
          rowsPerPageOptions={[10, 15, 20, 25, 50, 75, 100]}
          pageSize={pageSize}
          page={page}
          density="compact"
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: customNoRowsOverlay,
          }}
          onFilterModelChange={(model) => setFilters(model?.items[0])}
          onPageChange={handlePageChanger}
          onPageSizeChange={handlePageSizeChanger}
          loading={loading}
        />
      </Grid>
    </>
  );
};

const JobsInner = () => {
  const [fields, setFields] = useState({});
  const [formError, setFormError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const gqlMutation = (function () {
    let code = `  
      mutation(
        $id: Float,
        $active: Float,
        $createdby:Float,
        $createdon: Date!,
        $title: String,
        $description: String,
        $internalnotes: String,
        $technologies: String,
        $screeningtesturl: String,
        $updatedby: Float,
        $updatedon: Date!) {
              saveJobdescription(obj: {
        id:$id
        active:$active,
        createdby:$createdby,
        createdon:$createdon,
        title:$title,
        description:$description,
        internalnotes:$internalnotes,
        technologies:$technologies,
        screeningtesturl:$screeningtesturl,
        updatedby:$updatedby,
        updatedon:$updatedon,        
              }) {
        id
        active
        createdby
        createdon
        title
        description
        internalnotes
        technologies
        screeningtesturl
        updatedby
        updatedon   
                }
        }`;
    return gql`
      ${code}
    `;
  })();
  const [saveMutation4, { error: submitError }] = useMutation(gqlMutation, {
    context: authHeader(),
  });
  useEffect(() => {
    let fieldsM = {};
    let datanew = JSON.parse(localStorage.getItem("EditRowData"));
    if (datanew) {
      Object.entries(datanew).forEach(() => {
        setFields({ ...fieldsM });
      });
    }
  }, []);

  const deletePosts = (field) => {
    let fieldss = {};
    Object.assign(fieldss, {
      id: field.id,
      title: field.title,
      description: field.description,
      internalnotes: field.internalnotes,
      createdon: new Date(),
      createdby: field.createdby,
      updatedby: field.updatedby,
      updatedon: new Date(),
      technologies: field.technologies,
      screeningtesturl: field.screeningtesturl,
      active: 0,
    });
    saveMutation4({
      variables: {
        ...fieldss,
      },
      context: authHeader(),
    });
  };
  useEffect(() => {
    if (submitError) {
      setFormError(submitError.toString());
    }
  }, [submitError]);
  const deleteHandler = (item) => {
    deletePosts({ id: item.id, ...item });
  };

  return (
    <Grid>
      <Grid container xs={12} md={12} spacing={3}>
        <div className="inner-header">
          <div>
            <h4 className="page-title">Manage Jobs</h4>
          </div>
          <div>
            <Link className="navNames" to="/add-jobs">
              <Button color="primary" variant="outlined">
                {" "}
                Add New Job{" "}
              </Button>
            </Link>
          </div>
        </div>
      </Grid>
      <form id="jobform">
        <div id="formdivjob">
          <Grid
            style={{ padding: "0 15px", margin: "0px" }}
            container
            xs={12}
            md={12}
          >
            <Grid item xs={12} md={6}>
              <div id="error" style={{ color: "red" }}>
                {formError}
              </div>

              <Dialog
                id="jobdialog"
                open={dialogOpen}
                onClose={handleDialogClose}
                disableEscapeKeyDown={true}
                aria-describedby="alert-dialog-description"
              >
                <DialogContent id="jobcontent">
                  <div id="formdiv" style={{ width: "300px" }}>
                    <DialogContentText>Success!!</DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions id="jobaction">
                  <Button onClick={handleDialogClose} color="primary" autoFocus>
                    {"Ok"}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <DataGrid deleteHandler={deleteHandler} parentState={fields} />
          </Grid>
        </div>
      </form>
    </Grid>
  );
};
JobsInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(JobsInner);
function Job() {
  return <CompWithStyleInner />;
}
export default Job;

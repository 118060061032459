import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/client";
import { useHistory, Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import "../All.css";
import { editingResignationUser } from "../query.js";

const styles = makeStyles(() => ({}));

const AddEditResignationInner = () => {
  const [fields, setFields] = useState({
    notes: localStorage.getItem("EditRowData")
      ? JSON.parse(localStorage.getItem("EditRowData")).notes
      : "",
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formProcessing, setFormProcessing] = useState(false);

  let router = useHistory();

  const handleDialogClose = () => {
    setDialogOpen(false);
    router.push({ pathname: "/resignation-list" });

    setFormProcessing(false);
  };
  function submitCompleted() {
    setFormProcessing(true);
    setDialogOpen(true);
  }

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("EditRowData"));
    if (data) {
      Object.entries(data).forEach(() => {
        setFields({
          id: data.id,
          notes: data.notes,
        });
      });
    }
  }, []);

  const [saveMutation, { loading}] =
    useMutation(editingResignationUser, {
      variables: {
        id: fields.id,
      },
      onCompleted: submitCompleted,
      context: { clientName: "server2" },
      onError: (err) => {
        console.log(err, "ERR");
      },
    });
  const handleChange = (field, e) => {
    setFields((prev) => {
      return { ...prev, [`${field}`]: e.target.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveMutation({
      variables: {
        notes: fields.notes,
        id: fields.id,
      },
    });
  };

  return (
    <>
      <Grid container xs={12} md={12} >
        <div className="inner-header" id="resigheader">
          <div className="margin-auto" style={{ margin: "0px 38% auto" }}>
            <h4 className="page-title">Edit Notes</h4>
          </div>
          <div id="resigprimary">
            <Link className="navNames" to="/resignation-list">
              <Button color="primary" variant="outlined">
                {" "}
                Back{" "}
              </Button>
            </Link>
          </div>
        </div>
      </Grid>
      <form id="resigform">
        <div style={{ margin: "2%" }} id="resigdiv">
          <Grid container spacing={4}>
            <Grid container item xs={6} direction="column" id="resigngrid">
              <TextField
                variant="outlined"
                id="resignationnotes"
                label="Notes"
                type="text"
                multiline
                value={fields?.notes || ""}
                onChange={(e) => {
                  handleChange("notes", e);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
              />
            </Grid>

            <Grid item xs={12} id="resindiv">
              <Button
                color="primary"
                variant="contained"
                disabled={loading || formProcessing}
                onClick={(eventaa) => {
                  handleSubmit(eventaa);
                }}
                id="updateresignation1"
              >
                {(loading || formProcessing) && (
                  <span style={{ color: "white" }} id="resignspan">
                    <i
                      id="resigni"
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                    {"Please Wait..."}
                  </span>
                )}
                {!(loading || formProcessing) && <span>Update</span>}
              </Button>
              <Dialog
                id="resignationnotes"
                open={dialogOpen}
                onClose={handleDialogClose}
                disableEscapeKeyDown={true}
                aria-describedby="alert-dialog-description"
              >
                <DialogContent id="resignationnotescontent">
                  <div style={{ width: "300px" }}>
                    <DialogContentText>
                      Success!!
                    </DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions id="resignationnotesaction">
                  <Button onClick={handleDialogClose} color="primary" autoFocus>
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
};
AddEditResignationInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(
  AddEditResignationInner
);
function AddEditResignation() {
  return <CompWithStyleInner />;
}
export default AddEditResignation;

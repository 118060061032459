import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import { useHistory, Link } from "react-router-dom";
import { editingUserNotes } from "../query.js";
import { useMutation } from "@apollo/client";
import { authHeader } from "../config";
import PropTypes from "prop-types";
import "../All.css";

const styles = makeStyles(() => ({}));

const EditUserNotesInner = () => {
    const [fields, setFields] = useState({
        notes: localStorage.getItem("EditRowData")
          ? JSON.parse(localStorage.getItem("EditRowData")).notes
          : "",
      });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [formProcessing, setFormProcessing] = useState(false);

    let router = useHistory();

    const handleDialogClose = () => {
        setDialogOpen(false);
        router.push({ pathname: "/" });

        setFormProcessing(false);
    };
    function submitCompleted() {
        setFormProcessing(true);
        setDialogOpen(true);
    }

    useEffect(() => {
        let data = JSON.parse(localStorage.getItem("EditRowData"));
        if (data) {
        Object.entries(data).forEach(() => {
            setFields({
            id: data.id,
            notes: data.notes,
            });
        });
        }
    }, []);

    const [saveMutation, { loading}] =
        useMutation(editingUserNotes, {
        variables: {
           where: { id: fields.id },
           data: { notes: fields.notes }
        },
        onCompleted: submitCompleted,
        context:  authHeader(),
        onError: (err) => {
            console.log(err, "ERR");
        },
        });
    const handleChange = (field, e) => {
        setFields((prev) => {
        return { ...prev, [`${field}`]: e.target.value };
        });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveMutation({
      variables: {
        notes: fields.notes,
        id: fields.id,
      },
    });
  };    
    
    return (
        <>
        <Grid container xs={12} md={12} >
          <div className="inner-header" id="userheader">
            <div className="margin-auto" style={{ margin: "0px 38% auto" }}>
              <h4 className="page-title">Edit Notes</h4>
            </div>
            <div id="userprimary">
              <Link className="navNames" to="/">
                <Button color="primary" variant="outlined">
                  {" "}
                  Back{" "}
                </Button>
              </Link>
            </div>
          </div>
        </Grid>
        <form id="userform">
          <div style={{ margin: "2%" }} id="userdiv">
            <Grid container spacing={4}>
              <Grid container item xs={6} direction="column" id="usergrid">
                <TextField
                  variant="outlined"
                  id="usernotes"
                  label="Notes"
                  type="text"
                  multiline
                  value={fields?.notes || ""}
                  onChange={(e) => {
                    handleChange("notes", e);
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: false,
                  }}
                />
              </Grid>
  
              <Grid item xs={12} id="resindiv">
                <Button
                  color="primary"
                  variant="contained"
                  disabled={loading || formProcessing}
                  onClick={(eventaa) => {
                    handleSubmit(eventaa);
                  }}
                  id="updateuser"
                >
                  {(loading || formProcessing) && (
                    <span style={{ color: "white" }} id="userspan">
                      <i
                        id="resigni"
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                      {"Please Wait..."}
                    </span>
                  )}
                  {!(loading || formProcessing) && <span>Update</span>}
                </Button>
                <Dialog
                  id="usernotes"
                  open={dialogOpen}
                  onClose={handleDialogClose}
                  disableEscapeKeyDown={true}
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent id="usernotescontent">
                    <div style={{ width: "300px" }}>
                      <DialogContentText>
                        Success!!
                      </DialogContentText>
                    </div>
                  </DialogContent>
                  <DialogActions id="usernotesaction">
                    <Button onClick={handleDialogClose} color="primary" autoFocus>
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </div>
        </form>
      </>
    )
}

EditUserNotesInner.propTypes = {
    classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(
    EditUserNotesInner
);
function editUserNotes() {
    return <CompWithStyleInner />;
}
export default editUserNotes;
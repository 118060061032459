import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { gql, useMutation } from "@apollo/client";
import { useHistory, Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import "../All.css";
import { authHeader } from "../config";
import ClassicEditor from "classic-code-block";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const styles = makeStyles(() => ({}));

const AddEditJobsInner = () => {
  const [fields, setFields] = useState({
    title: "",
    description: getDescription(),
    internalnotes: "",
    createdon: new Date(),
    createdby: "",
    updatedby: "",
    updatedon: new Date(),
    technologies: "",
    screeningtesturl: "",
  });
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formProcessing, setFormProcessing] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("EditRowData")) {
      const data = JSON.parse(localStorage.getItem("EditRowData"));
      const id = JSON.parse(localStorage.getItem("id"));
      setFields({
        id: data.id,
        title: data.title,
        description: `<p>${data.description}</p>`,
        internalnotes: data.internalnotes,
        createdby: id,
        updatedby: id,
        technologies: data.technologies,
        screeningtesturl: data.screeningtesturl,
      });
    }
  }, []);
  function getDescription() {
    return localStorage.getItem("EditRowData")
      ? JSON.parse(localStorage.getItem("EditRowData")).description
      : "";
  }
  let router = useHistory();

  const handleDialogClose = () => {
    setDialogOpen(false);
    router.push({ pathname: "/jobs" });
    setFormProcessing(false);
  };
  function submitComplete() {
    setFormProcessing(true);
    setDialogOpen(true);
  }

  const gqlMutation2 = (function () {
    let code = ` mutation(
        $id: Float,
        $active: Float,
        $createdby:Float,
        $createdon: Date!,
        $title: String,
        $description: String,
        $internalnotes: String,
        $technologies: String,
        $screeningtesturl: String,
        $updatedby: Float,
        $updatedon: Date!) {
              saveJobdescription(obj: {
                id: $id,  
        active:$active,
        createdby:$createdby,
        createdon:$createdon,
        title:$title,
        description:$description,
        internalnotes:$internalnotes,
        technologies:$technologies,
        screeningtesturl:$screeningtesturl,
        updatedby:$updatedby,
        updatedon:$updatedon,        
              }) {
        id
        active
        createdby
        createdon
        title
        description
        internalnotes
        technologies
        screeningtesturl
        updatedby
        updatedon   
                }
        }`;
    return gql`
      ${code}
    `;
  })();

  const [saveMutation1, { loading, error: submitError }] = useMutation(
    gqlMutation2,
    {
      variables: {
        id: fields.id,
        title: fields.title,
        description: fields.description,
        internalnotes: fields.internalnotes,
        createdon: new Date(),
        createdby: JSON.parse(localStorage.getItem("id")),
        updatedby: JSON.parse(localStorage.getItem("id")),
        updatedon: new Date(),
        technologies: fields.technologies,
        screeningtesturl: fields.screeningtesturl,
        active: fields.active,
      },
      onCompleted: submitComplete,
      context: authHeader(),
    }
  );

  useEffect(() => {
    console.log(fields);
  }, [fields]);

  useEffect(() => {
    setFormError(submitError ? submitError.toString() : "");
  }, [submitError]);

  const makePost = () => {
    saveMutation1({
      variables: {
        id: fields.id,
        title: fields.title,
        description: fields.description,
        internalnotes: fields.internalnotes,
        createdon: new Date(),
        createdby: JSON.parse(localStorage.getItem("id")),
        updatedby: JSON.parse(localStorage.getItem("id")),
        updatedon: new Date(),
        technologies: fields.technologies,
        screeningtesturl: fields.screeningtesturl,
      },
    });
  };

  const validForm = () => {
    let valid = true;
    if (!fields.title.length > 0) {
      setErrors((prev) => {
        return { ...prev, title: "Title is required" };
      });
      valid = false;
    }
    if (fields.screeningtesturl.length > 0) {
      const regex =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
      if (!regex.test(fields.screeningtesturl)) {
        setErrors((prev) => {
          return { ...prev, screeningtesturl: "Provide a valid URL" };
        });
        valid = false;
      }
    }
    if (!fields.description.length > 0) {
      setErrors((prev) => {
        return { ...prev, description: "Description is required" };
      });
      valid = false;
    }
    return valid;
  };

  const handleChange = (field, value) => {
    delete errors[`${field}`];
    setFields((prev) => {
      return { ...prev, [`${field}`]: value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validForm()) {
      makePost();
    }
  };
  return (
    <>
      <Grid container xs={12} md={12} spacing={3}>
        <div className="inner-header" id="div1">
          <div
            className="margin-auto"
            id="div2"
            style={{ margin: "0px 38% auto" }}
          >
            <h4 className="page-title" id="page-title">
              {JSON.parse(localStorage.getItem("EditRowData")) ? "Edit" : "Add"}{" "}
              Jobs
            </h4>
          </div>
          <div>
            <Link className="navNames" to="/jobs">
              <Button color="primary" variant="outlined">
                {" "}
                Back{" "}
              </Button>
            </Link>
          </div>
        </div>
      </Grid>
      <form id="addjobss">
        <div style={{ margin: "2%" }} id="jobsadd">
          <Grid container spacing={4}>
            <Grid container item xs={6} direction="column">
              <TextField
                variant="outlined"
                id="title-note"
                label="Title"
                type="text"
                value={fields?.title?.trim() || ""}
                onChange={(event) => {
                  handleChange("title", event.target.value);
                }}
                onBlur={(event) => {
                  handleChange("title", event.target.value);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                required
              />
              <div style={{ color: "red" }}>{errors["title"]}</div>
            </Grid>

            <Grid container item xs={6} direction="column">
              <TextField
                variant="outlined"
                id="technologies"
                label="Technologies"
                type="text"
                value={fields?.technologies?.trim() || ""}
                onChange={(e) => {
                  handleChange("technologies", e.target.value);
                }}
                onBlur={(e) => {
                  handleChange("technologies", e.target.value);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                // required
              />
            </Grid>

            <Grid container item xs={6} direction="column">
              <TextField
                variant="outlined"
                id="screeningtesturl"
                label="Screen Test Url"
                type="text"
                value={fields.screeningtesturl || ""}
                onChange={(e) => {
                  handleChange("screeningtesturl", e.target.value);
                }}
                onBlur={(e) => {
                  handleChange("screeningtesturl", e.target.value);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                // required
              />
              <div style={{ color: "red" }}>{errors["screeningtesturl"]}</div>
            </Grid>

            <Grid container item xs={6} direction="column">
              <TextField
                variant="outlined"
                id="internalnotes"
                label="Internal Notes"
                type="text"
                value={fields?.internalnotes?.trim() || ""}
                onChange={(e) => {
                  handleChange("internalnotes", e.target.value);
                }}
                onBlur={(e) => {
                  handleChange("internalnotes", e.target.value);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                // required
              />
            </Grid>

            <Grid container item xs={6} direction="column">
              <label>Description *</label>
              <CKEditor
                editor={ClassicEditor}
                data={fields?.description}
                onChange={(_event, editor) => {
                  const data = editor.getData();
                  handleChange("description", data);
                }}
              />
              <div style={{ color: "red" }}>{errors["description"]}</div>
            </Grid>

            <Grid item xs={12} id="formerror">
              <div style={{ color: "red" }} id="formerrors">
                {formError}
              </div>
              <Button
                color="primary"
                variant="contained"
                disabled={loading || formProcessing}
                onClick={(event) => {
                  handleSubmit(event);
                }}
                id="updateSubmit"
              >
                {(loading || formProcessing) && (
                  <span style={{ color: "white" }} id="msg">
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Please Wait...
                  </span>
                )}
                {!(loading || formProcessing) && (
                  <span>
                    {localStorage.getItem("EditRowData") ? "Update" : "Submit"}
                  </span>
                )}
              </Button>
              <Dialog
                id="addjobform"
                open={dialogOpen}
                onClose={handleDialogClose}
                disableEscapeKeyDown={true}
                aria-describedby="alert-dialog-description"
              >
                <DialogContent id="addformjob">
                  <div style={{ width: "300px" }} id="formdiv">
                    <DialogContentText id="alert-dialog-description">
                      {"Success!!"}
                    </DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions id="formactionjobs">
                  <Button onClick={handleDialogClose} color="primary" autoFocus>
                    {"Ok"}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
};
AddEditJobsInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(
  AddEditJobsInner
);
function AddEditJobs() {
  return <CompWithStyleInner />;
}
export default AddEditJobs;

import React from 'react'
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function DatePicker({error,minDate,field,date,setdate,title}) {
  const handleDateChange = (date1) => {
    delete error[field]
    setdate(prev => {return {...prev,[`${field}`]:date1}});
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifyContent="space-around">
      <KeyboardDatePicker
          margin="normal"
          label={title}
          format="MM/dd/yyyy"
          value={date}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          minDate={minDate}
          autoOk={true}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
import URLDATA from "../config";
import React, { useEffect, useState } from "react";
import DefaultImage from "./Default.png";
import Rating from "@material-ui/lab/Rating";
import { Link } from "react-router-dom";
import { getAllUserProjectsBasedOnCondition } from "../query.js";
import "./Customer.css";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

function dataFormatter(data) {
  const clientData = JSON.parse(localStorage.getItem("rowData"));
  return Object.values(data).reduce((data12, curr) => {
    if (curr.customer.id === clientData.id) data12.push(curr);
    return data12;
  }, []);
}

export default function CustomerDetails() {
  const [clientDetails, setClientDetails] = useState({});
  const [projectData, setProjectData] = useState({});
  const [show, setShow] = useState(true);

  useEffect(() => {
    setClientDetails(JSON.parse(localStorage.getItem("rowData")));
    fetch(URLDATA.url1, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: getAllUserProjectsBasedOnCondition,
        variables: {
          isActive: false,
          projectTitle: "",
          pageSize: 1000,
          pageNumber: 0,
          managerId: 0,
          projectType: "",
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        data = dataFormatter(data?.data?.getAllUserProjectsBasedOnCondition);
        setProjectData(data);
        setInterval(() => {
          setShow(false);
        }, 200);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <>
      <h2 style={{ position: "absolute", top: "0", left: "45%" }}>
        Client Details
      </h2>
      <Link
        style={{ position: "absolute", top: "3%", left: "90%" }}
        to="/customer"
      >
        <Button color="primary" variant="outlined">
          {" "}
          Back{" "}
        </Button>
      </Link>
      <div className="container">
        <div className="imagesection">
          <div className="imagediv">
            <img src={DefaultImage} alt=""></img>
          </div>
          <div className="detailsdiv">
            <div>
              <label>Name : {clientDetails.name}</label>
            </div>
            <div>
              <label>Manager : {clientDetails.manager}</label>
            </div>
            <div>
              <Rating
                value={clientDetails.rating ? clientDetails.rating : ""}
                readOnly
              ></Rating>
            </div>
            <div>
              <label
                style={
                  clientDetails.status === 1
                    ? { color: "green" }
                    : { color: "red" }
                }
              >
                Status : {clientDetails.status === 1 ? "Active" : "Inactive"}
              </label>
            </div>
            <div>
              <label>Revenue : {clientDetails.clientrevenue}</label>
            </div>
            <div style={{ marginTop: "10px" }}>
              <label>
                N.D.A :{" "}
                {clientDetails.nda === 1 ? (
                  <DoneIcon style={{ color: "green" }} />
                ) : (
                  <CloseIcon style={{ color: "red" }} />
                )}
              </label>
            </div>
            <div>
              <label>
                N.O.C :{" "}
                {clientDetails.noc === 1 ? (
                  <DoneIcon style={{ color: "green" }} />
                ) : (
                  <CloseIcon style={{ color: "red" }} />
                )}
              </label>
            </div>
            <div>
              <label>M.S.W/S.O.W : </label>
              {clientDetails.msw === 1 ? (
                <DoneIcon style={{ color: "green" }} />
              ) : (
                <CloseIcon style={{ color: "red" }} />
              )}
            </div>
          </div>
        </div>
        <div className="sectionde">
          <h2>Active Projects</h2>
          {show
            ? ""
            : Object.values(projectData).map((item, index) => {
                return item.isActive ? (
                  <div key={index} className="projectDetails">
                    <label>Project : </label>
                    <span>{item.projectTitle}</span>
                    <br />
                    <label>Assigned members : </label>
                    <br />
                    <ul style={{ listStyle: "none" }}>
                      {item.projectUser.map((user) => (
                        <li>{user.name}</li>
                      ))}
                    </ul>
                  </div>
                ) : null;
              })}
        </div>
        <div className="sectionde">
          <h2>Deactivated Projects</h2>
          {show ? (
            <CircularProgress
              style={{ position: "absolute", top: "50%", left: "60%" }}
            />
          ) : (
            Object.values(projectData).map((item, index) => {
              return item.isActive ? null : (
                <div key={index} className="projectDetails">
                  <label>Project : </label>
                  <span>{item.projectTitle}</span>
                  <br />
                  <label>Assigned members : </label>
                  <br />
                  <ul style={{ listStyle: "none" }}>
                    {item.projectUser.map((user) => (
                      <li>{user.name}</li>
                    ))}
                  </ul>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
}

import ListAltIcon from "@mui/icons-material/ListAlt";
import CreateIcon from "@mui/icons-material/Create";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";

// Module name should be same on the both sheets SIDEBARLIST and ROUTES
const list = [
  {
    link: "/",
    label: "User Availability",
    moduleName: "userAvailability",
    icon: <ListAltIcon />,
  },

  {
    link: "/test-questions",
    label: "Manage Questions",
    moduleName: "manageQuestion",
    icon: <AppRegistrationIcon />,
  },

  {
    link: "/tests",
    label: "Manage Tests",
    moduleName: "managetests",
    icon: <CreateIcon />,
  },

  {
    link: "/vendors",
    label: "Vendors",
    moduleName: "vendors",
    icon: <SupervisedUserCircleIcon />,
  },

  {
    link: "/jobs",
    label: "Jobs",
    moduleName: "job",
    icon: <WorkIcon />,
  },

  {
    link: "/college",
    label: "College",
    moduleName: "college",
    icon: <SchoolIcon />,
  },

  {
    link: "/customer",
    label: "Client",
    moduleName: "customer",
    icon: <SchoolIcon />,
  },
  {
    link: "/probation",
    label: "Probation Employees",
    moduleName: "probation",
    icon: <SchoolIcon />,
  },
  {
    link: "/resignation-list",
    label: "Resigned Employees",
    moduleName: "resignation",
    icon: <SchoolIcon />,
  },
];

const sideBarlist = () => {
  return list;
};

export default sideBarlist;

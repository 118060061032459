import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { gql, useMutation } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import {
  DataGrid as DataGridElement,
  GridToolbar,
  GridOverlay,
} from "@material-ui/data-grid";
import Edit from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import swal from "sweetalert";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import URLDATA, { authHeader } from "../config";
import { useHistory } from "react-router";
import { getAllCustomers } from "../query.js";
import { usePersistedState } from "../utils/usePersistedState";

import Autocomplete from "@mui/material/Autocomplete";

const styles = () => ({});
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const DataGrid = (props) => {
  const [page, setPage] = usePersistedState("Page", 0, "customer");
  const [pageSize, setPageSize] = usePersistedState("PageSize", 10, "customer");
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState(1);
  const [clientStatus, setClientStatus] = useState("Active Clients");
  const [columns, setColumns] = useState([]);
  const [clientRevenue, setClientRevenue] = useState("");
  const [clientRevenueData, setClientRevenueData] = useState(undefined);
  const [filter, setFilter] = useState({});
  const [prevPage, setPrevPage] = useState(0);

  useEffect(() => {
    localStorage.removeItem("rowData")
    setColumns(gridcolumns);
  }, []);

  useEffect(() => {
    setPage(0);
    showClientStatusData();
  }, [clientStatus]);

  useEffect(() => {
    showClientRevenueData();
  }, [clientRevenue]);

  useEffect(() => {
    setLoading(true);
    fetchCollegeList();
  }, [clientData, clientRevenueData]);

  useEffect(() => {
    if (filter.value === undefined || filter.value === "") {
      setLoading(true);
      fetchCollegeList();
    }
  }, [page, pageSize]);

  useEffect(() => {
    if (filter.value !== undefined && filter.value !== "") {
      setPrevPage(page);
      setPage(0);
    } else if (prevPage !== 0) setPage(prevPage);
  }, [filter]);

  function customNoRowsOverlay() {
    return (
      <GridOverlay>
        <div>No Data Found</div>
      </GridOverlay>
    );
  }

  const fetchCollegeList = async () => {
    fetch(URLDATA.url1, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        query: getAllCustomers,
        variables: {
          isActive: clientData,
          clientrevenue: clientRevenueData,
          pageSize: pageSize,
          pageNumber: page,
        },
        context: { clientName: "server2" },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        data = data?.data?.getAllCustomers;
        setRows(data);
        setTotalCount(data.length > 0 ? data[0].totalcount : 0);
        setLoading(false);
      });
  };

  const handlePageChange = (param) => {
    setPage(param);
  };

  const handlePageSizeChange = async (params) => {
    setLoading(true);
    await setPage(0);
    await setPageSize(params);
  };

  let router = useHistory();
  let gridcolumns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => {
        return (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              localStorage.setItem("rowData", JSON.stringify(params.row));
              router.push("/customer-details");
            }}
          >
            {params.row.name}
          </p>
        );
      },
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 200,
      renderCell: (params) => {
        return <Rating name="read-only" value={params.row.rating} readOnly />;
      },
    },
    {
      field: "clientRevenue",
      headerName: "Revenue",
      width: 200,
      renderCell: (params) => {
        return <p>{params.row.clientRevenue} </p>;
      },
    },
    {
      field: "manager",
      headerName: "Manager",
      width: 200,
      renderCell: (params) => {
        return <p>{params.row.manager}</p>;
      },
    },
    {
      field: "startdate",
      headerName: "Contract Starts",
      width: 200,
      renderCell: (params) => {
        return <p>{params.row.startdate} </p>;
      },
    },
    {
      field: "enddate",
      headerName: "Contract Ends",
      width: 200,
      renderCell: (params) => {
        return <p>{params.row.enddate}</p>;
      },
    },
    {
      field: "insuranceDate",
      headerName: "Insurance Date",
      width: 200,
      renderCell: (params) => {
        return <p>{params.row.insuranceDate}</p>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        return (
          <p>{params.row.status === 1 ? <p>Active</p> : <p>Inactive</p>} </p>
        );
      },
    },
    {
      field: "profilestatus",
      disableExport: true,
      headerName: "Profile-Status",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <LightTooltip
              title={
                <div>
                  <p
                    style={
                      params.row.startdate
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    Contract Start Date
                  </p>
                  <p
                    style={
                      params.row.enddate ? { color: "green" } : { color: "red" }
                    }
                  >
                    Contract End Date
                  </p>
                  <p
                    style={
                      params.row.insuranceDate
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    Insurance Date
                  </p>
                  <p
                    style={
                      params.row.noc ? { color: "green" } : { color: "red" }
                    }
                  >
                    N.O.C
                  </p>
                  <p
                    style={
                      params.row.nda ? { color: "green" } : { color: "red" }
                    }
                  >
                    N.D.A
                  </p>
                  <p
                    style={
                      params.row.msw ? { color: "green" } : { color: "red" }
                    }
                  >
                    M.S.W/S.O.W
                  </p>
                </div>
              }
              arrow
              placement="right"
            >
              <p>{params.row.profilestatus}%</p>
            </LightTooltip>
          </>
        );
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 300,
      renderCell: (params) => {
        return (
          <div>
            <p>{params.row.comments}</p>
          </div>
        );
      },
    },
    { field: "action", disableExport: true, headerName: "Actions", width: 150 },
  ].filter((item) => {
    return item.field !== "action";
  });

  gridcolumns = [
    ...gridcolumns,
    {
      disableExport: true,
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <strong>
          <Edit
            onClick={() => {
              let dataRow = params.row;
              localStorage.setItem(
                "EditRowData",
                JSON.stringify({
                  ...dataRow,
                })
              );
              router.push({ pathname: "/edit-customer" });
            }}
            style={{ marginInline: "4px", cursor: "pointer" }}
          />

          <TrashIcon
            onClick={() => {
              swal({
                title: "Are you sure?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  props.deleteHandler({
                    ...params.row,
                    id: params.row.id,
                  });
                  //   getUpdatedList()
                  swal("Client has been deleted!", {
                    icon: "success",
                  });
                  setLoading(true);
                  fetchCollegeList();
                }
              });
            }}
            style={{ marginInline: "4px", cursor: "pointer" }}
          />
        </strong>
      ),
    },
  ];

  const showClientStatusData = () => {
    if (clientStatus === "") {
      setClientData(1);
    }
    if (clientStatus === "Active Clients") {
      setClientData(1);
    }
    if (clientStatus === "Inactive Clients") {
      setClientData(0);
    }
    if (clientStatus === "All Clients") {
      setClientData(undefined);
    }
  };

  const showClientRevenueData = () => {
    if (clientRevenue === "STARTUP") setClientRevenueData("STARTUP");
    if (clientRevenue === "<1M") setClientRevenueData("<1M");
    if (clientRevenue === "1M-10M") setClientRevenueData("1M-10M");
    if (clientRevenue === "10-100M") setClientRevenueData("10-100M");
    if (clientRevenue === "100-500M") setClientRevenueData("100-500M");
    if (clientRevenue === "500M+") setClientRevenueData("500M+");
  };

  const cilentStatusDataOption = [
    "Active Clients",
    "Inactive Clients",
    "All Clients",
  ];

  const clientRevenueDataOption = [
    "STARTUP",
    "<1M",
    "1M-10M",
    "10-100M",
    "100-500M",
    "500M+",
  ];

  const doClearClientRevenue = () => {
    setClientRevenueData(undefined);
    setClientData(1);
    setLoading(true);
    fetchCollegeList();
    setClientStatus("Active Clients");
    setClientRevenue("");
  };
  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "600px",
          }}
        >
          <div>
            <Autocomplete
              style={{ width: "198px" }}
              value={clientStatus}
              onChange={(_e, val) => {
                setClientStatus(val);
              }}
              selectOnFocus
              clearOnBlur
              disableClearable
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={cilentStatusDataOption}
              sx={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Client status"
                  placeholder="Client status"
                />
              )}
            />
          </div>
          <div>
            <Autocomplete
              style={{ width: "198px" }}
              value={clientRevenue}
              onChange={(_e, val) => {
                setClientRevenue(val);
              }}
              selectOnFocus
              clearOnBlur
              disableClearable
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={clientRevenueDataOption}
              sx={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Client revenue"
                  placeholder="Client revenue"
                />
              )}
            />
          </div>
          <Button
            style={{ margin: "6px", width: "100px" }}
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={doClearClientRevenue}
            id="gridClear"
          >
            Clear
          </Button>
        </div>
      </Grid>

      <Grid container xs={12} style={{ height: "80vh" }}>
        <DataGridElement
          columns={columns}
          rows={rows}
          rowCount={totalCount}
          rowsPerPageOptions={[10, 15, 20, 25, 50, 75, 100]}
          pageSize={pageSize}
          page={page}
          density="compact"
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: customNoRowsOverlay,
          }}
          onFilterModelChange={(model) => setFilter(model?.items[0])}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          loading={loading}
        />
      </Grid>
    </>
  );
};

const CustomerInner = () => {
  const [fields, setFields] = useState({});
  const [formError, setFormError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const gqlMutation = (function () {
    let code = `  
      mutation(
        $id: Float,
        $status: Float,
        $name: String!,
        $managerid: Float,
        $insurancedate:Date,
        $startdate:Date,
        $enddate:Date,
        $clientrevenue:String,
        $createdby:String,
        $createdat: Date!,
        $modifiedby: String,
        $updatedat: Date!,
        ) {
              saveCustomers(obj: {
        id:$id,          
        status:$status,
        name:$name,
        managerid:$managerid,
        insurancedate :$insurancedate,
        startdate:$startdate,
        enddate:$enddate,
        clientrevenue:$clientrevenue,
        createdat:$createdat,
        createdby:$createdby,        
        modifiedby:$modifiedby,
        updatedat:$updatedat,
        }) {
        id
        status
        managerid
        insurancedate
        clientrevenue
        createdat
        createdby
        modifiedby
        updatedat
        startdate
        enddate
          }
        }`;
    return gql`
      ${code}
    `;
  })();

  const [saveMutation, { error: submitError }] = useMutation(gqlMutation, {
    context: authHeader(),
  });

  useEffect(() => {
    let fieldsT = {};
    let data = JSON.parse(localStorage.getItem("EditRowData"));
    if (data) {
      Object.entries(data).forEach(() => {
        setFields({ ...fieldsT });
      });
    }
  }, []);

  useEffect(() => {
    if (submitError) {
      setFormError(submitError.toString());
    }
  }, [submitError]);

  const deletePost = (field) => {
    let fieldsT = {};
    Object.assign(fieldsT, {
      address: field.address,
      name: field.name,
      contactperson: field.contactperson,
      createdby: field.createdby,
      createdat: new Date(),
      location: field.location,
      emailid: field.skillId,
      mobilenumber: field.mobilenumber,
      otherdetails: field.otherdetails,
      phonenumber: field.phonenumber,
      rating: field.rating,
      insurancedate: field.insurancedate,
      startdate: field.startdate,
      enddate: field.enddate,
      updatedby: field.updatedby,
      updatedat: new Date(),
      website: field.website,
      id: field.id,
      status: 0,
    });
    saveMutation({
      variables: {
        ...fieldsT,
      },
      context: authHeader(),
    });
  };

  const deleteHandler = (item) => {
    deletePost({ id: item.id, ...item });
  };

  return (
    <Grid>
      <Grid container xs={12} md={12} spacing={3}>
        <div className="inner-header">
          <div>
            <h4 className="page-title">Manage Client</h4>
          </div>

          <div>
            <Link className="navNames" to="/add-customer">
              <Button color="primary" variant="outlined">
                {" "}
                Add New Client
              </Button>
            </Link>
          </div>
        </div>
      </Grid>
      <form>
        <div>
          <Grid
            style={{ padding: "0 15px", margin: "0px" }}
            container
            xs={12}
            md={12}
            spacing={3}
          >
            <Grid item xs={12} md={6}>
              <div style={{ color: "red" }}>{formError}</div>

              <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                disableEscapeKeyDown={true}
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <div style={{ width: "300px" }}>
                    <DialogContentText id="alert-dialog-description">
                      {"Success!!"}
                    </DialogContentText>
                  </div>
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleDialogClose} color="primary" autoFocus>
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <DataGrid deleteHandler={deleteHandler} parentState={fields} />
          </Grid>
        </div>
      </form>
    </Grid>
  );
};
CustomerInner.propTypes = {
  classes: PropTypes.object.isRequired,
};

const CompWithStyleInner = withStyles(styles, { withTheme: true })(
  CustomerInner
);

function Customer() {
  return <CompWithStyleInner />;
}

export default Customer;

import React, { Component } from 'react';
import "./slider.css"
import img1 from "./images/image1.svg";

const slides = [
    {
        img: img1,
        color: "blue-curve",
        text: "Plan your work easily to work effectively"
    },
    {
        img: img1,
        color: "yellow-curve",
        text: "Plan your work easily to work effectively "
        // text:"Empower and help Small $ Medium enterprises to grow by providing reliable and cost effective services and solutions-Qss's Visison"
    },
    {
        img: img1,
        color: "red-curve",
        text: "Plan your work easily to work effectively "
    }

]

class Slider extends Component {
    constructor() {
        super();
        this.changeCurveColor = this.changeCurveColor.bind(this);
        this.sliderButtonFunction = this.sliderButtonFunction.bind(this);
        this.state = {
            slide: slides[0],
            slideNumber: 0,
            run: true
        }
    }
    componentDidMount() {
        let intervalId = setInterval(this.changeCurveColor, 4000) //Clear this interval when the component unmounts  
        this.setState({ intervalId: intervalId });
    }

    sliderButtonFunction(slideNumber) {
        this.setState({
            ...this.state,
            slide: slides[slideNumber],
            slideNumber
        })
    }

    changeCurveColor() {

        const slideNumber = (this.state.slideNumber === 2) ? 0 : this.state.slideNumber + 1;
        this.setState({
            slide: slides[slideNumber],
            slideNumber
        })
    }
    componentWillUnmount() {
        // clearing the interval after unmountint the component
        clearInterval(this.state.intervalId);
    }

    render() {
        return (
            <div>
                <div id="slider-container">
                    <div id="slider">
                        <h3>{this.state.slide.text}</h3>
                        <img src={this.state.slide.img} alt="" /><br />
                        {/* <div className = "slide-navigationButtons">
                        <button onClick = {e => this.sliderButtonFunction(0)} className = "rounded-button"></button>
                        <button onClick = {e => this.sliderButtonFunction(1)} className ="rounded-button"></button>
                        <button onClick = {e => this.sliderButtonFunction(2)} className = "rounded-button"></button>
                    </div> */}
                    </div>
                </div>
                <div id="curve" className={this.state.slide.color}></div>
            </div>

        );
    }
}

export default Slider;
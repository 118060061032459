import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { gql, useMutation } from "@apollo/client";
import { useHistory,Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { getAllActiveUsers,getAllCustomers } from "../query.js";
import "../All.css";
import URLDATA, { authHeader } from "../config";
import { UserDocumentLabel } from "./Reusing";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import DatePicker from "./DatePicker.jsx";
import "./Customer.css";

const styles = makeStyles(() => ({}));

const AddEditCustomerInner = () => {
  const [fields, setFields] = React.useState({
    status: 1,
    startdate: null,
    enddate: null,
    insurancedate: null,
    name: null,
  });
  const [errors, setErrors] = React.useState({});
  const [formError, setFormError] = React.useState("");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [formProcessing, setFormProcessing] = React.useState(false);
  const [accManagers, setAccManagers] = useState([]);
  let router = useHistory();

  const handleDialogClose = () => {
    setDialogOpen(false);
      router.push({ pathname: "/customer" });
    setFormProcessing(false);
  };

  function submitCompleted() {
    setFormProcessing(true);
    setDialogOpen(true);
  }

  const gqlMutation8 = (function () {
    let code = `mutation(
        $id: Float,
        $name: String!,
        $rating: Float,
        $status:Float,
        $managerid:Float,
        $comments:String,
        $insurancedate: Date,
        $clientrevenue:String,
        $createdat: Date!,
        $updatedat: Date!,
        $startdate:Date,
        $noc:Float,
        $nda:Float,
        $msw:Float,
        $enddate:Date,
        ) {
              saveCustomers(obj: {
        id: $id,          
        name:$name,
        rating: $rating,
        managerid: $managerid,
        comments: $comments,
        insurancedate : $insurancedate,
        clientrevenue: $clientrevenue,
        status:$status,
        noc:$noc,
        nda:$nda,
        msw:$msw,
        createdat:$createdat,
        updatedat:$updatedat,
        startdate:$startdate,
        enddate:$enddate,
        }) {
          id
          rating
          managerid
          comments
          insurancedate
          clientrevenue
          status
          startdate
          enddate
          noc
          nda
          msw
        }
        }`;
    return gql`
      ${code}
    `;
  })();

  const [saveMutation8, { loading, error: submitError }] =
    useMutation(gqlMutation8, {
      variables: {
        rating: fields.rating,
        active: fields.active,
        name: fields.name,
        clientrevenue: fields.clientRevenue,
        managerid: fields.managerid,
        noc: fields.noc,
        nda: fields.nda,
        msw: fields.msw,
        comments: fields.comments,
        insurancedate: fields.insurancedate,
        status: fields.status,
        // createdby: JSON.parse(localStorage.getItem("id")),
        createdat: new Date(),
        // updatedby: JSON.parse(localStorage.getItem("id")),
        updatedat: new Date(),
        startdate: fields.startdate,
        enddate: fields.enddate,
        id: fields.id,
      },
      onCompleted: submitCompleted,
      context: authHeader(),
      onError: (err) => {
        console.log(err, "ERR");
      },
    });

  // check-point
  useEffect(() => {
    fetch(URLDATA.url1, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: getAllActiveUsers,
        variables: {
          chars: "",
          pageNumber: 0,
          pageSize: 1000000,
          isActive: 0,
          managerId: 0,
        },
        context: { clientName: "server2" },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setAccManagers(data?.data?.getAllActiveUser);
      });
  }, []);

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("EditRowData"));
    if (data) {
      Object.entries(data).forEach(() => {
        setFields({
          name: data.name,
          id: data.id,
          rating: data.rating,
          status: data.status,
          clientRevenue: data.clientrevenue,
          managerName: data.managerName,
          managerid: data.managerid,
          comments: data.comments,
          insurancedate: data.insurancedate,
          startdate: data.startdate,
          enddate: data.enddate,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (submitError) {
      if (submitError.toString().includes("ER_DUP_ENTRY")) {
        setFormError("Client name already exist");
      } else {
        setFormError(submitError.toString());
      }
    }
  }, [submitError]);

  const makePost = () => {
    let fieldsT = {};
    Object.assign(fieldsT, fields);

    let data = JSON.parse(localStorage.getItem("EditRowData"));
    fetch(URLDATA.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
        xcvtoken: localStorage.getItem("id"),
      },

      body: JSON.stringify({
        query: getAllCustomers,
        variables: {
          where: {
            rating: fieldsT.rating,
            name: fieldsT.name,
            clientrevenue: fieldsT.clientRevenue,
            status: fieldsT.status,
          },
        },
        context: { clientName: "server1" },
      }),
    })
      .then((res) => res.json())
      .then((existRes) => {
        const { data: { allCustomers: { Customers = [] } = [] } = [] } =
          existRes;
        if (data) {
          Object.assign(fieldsT, fields);

          if (Customers.length) {
            if (Customers.filter((item) => item.id === fieldsT.id).length) {
              saveMutation8({
                variables: {
                  id: fieldsT.id,
                  name: fieldsT.name,
                  clientrevenue: fieldsT.clientRevenue,
                  rating: fieldsT.rating,
                  status: fieldsT.status,
                  managerid: fieldsT.managerid,
                  comments: fieldsT.comments,
                  insurancedate: fieldsT.insurancedate,
                  startdate: fieldsT.startdate,
                  enddate: fieldsT.enddate,
                  updatedat: new Date(),
                  createdon: new Date(),
                },
              });
            } else {
              setErrors({ ...errors, name: "Client name already exist" });
            }
          } else {
            // setErrors({...errors,['name']:"Client name already exist"})
            saveMutation8({
              variables: {
                input: fieldsT,
              },
            });
          }
        } else {
          if (Customers.length) {
            setErrors({ ...errors,name: "Client name already exist" });
          } else {
            saveMutation8({
              variables: {
                input: fieldsT,
              },
            });
          }
        }
      });
  };

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid9 = true;
    let fieldValue = e && e.target ? e.target.value : e;
    let updatedValue = fieldValue;
    //Customer Name validations
    if (fieldname === undefined || fieldname === "name") {
      if (fieldname === undefined) {
        fieldValue = fields["name"] ? fields["name"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["name"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid9 = false;
        errorsT["name"] = "Name is required";
      }
    }
    setErrors({ ...errorsT });
    return { formIsValid9, updatedValue };
  };

  const handleChange = (field, e) => {
    const {  updatedValue } = handleValidation(field, e);
    let fieldsT = fields;
    if (field === "managerName" && updatedValue !== null) {
      const managerid = accManagers.filter(
        (item) => item.name === updatedValue
      )[0];
      fieldsT["managerid"] = managerid.id;
    }
    fieldsT[field] = updatedValue;
    setFields({ ...fieldsT });
  };

  const handlecomplete = (e) => {
    e.preventDefault();
    const { formIsValid9} = handleValidation();
    let fieldsT = {};
    Object.assign(fieldsT, fields);
    if (!fieldsT.hasOwnProperty("status")) fieldsT["status"] = 0;
    if (formIsValid9) {
      makePost();
    }
  };

  const clientRevenueDataOption = [
    "STARTUP",
    "<1M",
    "1M-10M",
    "10-100M",
    "100-500M",
    "500M+",
  ];
  return (
    <>
      <Grid item xs={12} className="inner-header">
        <Grid item md={4}></Grid>
        <Grid item xs={6} md={5}>
          <h4 className="page-title">
            {JSON.parse(localStorage.getItem("EditRowData")) ? "Edit" : "Add"}{" "}
            Client
          </h4>
        </Grid>
        <Grid item md={1}>
          <Link className="navNames" to="/customer">
            <Button color="primary" variant="outlined">
              {" "}
              Back{" "}
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid className={`mainsection ${localStorage.getItem("EditRowData")?"main":"addmain"}`}>
        <Grid className="detailsection">
          <form className={`formsection ${localStorage.getItem("EditRowData")?"sectionWidth":"fullWidth"}`}>
            <div className="formitem1">
              <div className="nameItem">
              <TextField
                variant="outlined"
                id="name"
                label="Client Name"
                type="text"
                style={{ width: "100%" }}
                value={
                  fields.name === " "
                    ? fields?.name?.trim()
                    : fields?.name || undefined
                }
                onChange={(e) => {
                  setFormError(null);
                  handleChange("name", e);
                }}
                onBlur={(e) => {
                  handleChange("name", e);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                required
              />
              <div style={{ color: "red" }}>{errors["name"]}</div>
              <div style={{ color: "red" }}>{formError}</div>
              </div>
              <TextField
                variant="outlined"
                sx={{
                  ".MuiOutlinedInput-root": {
                    paddingTop: "1rem",
                    flexDirection: "column",
                  },
                  Rating: {
                    paddingRight: "1rem",
                  },
                }}
                style={{ width: "48%" }}
                InputProps={{
                  startAdornment: (
                    <Rating
                      name="simple-controlled"
                      value={fields.rating || 0}
                      onChange={(_, newValue) => {
                        if (!newValue) newValue = fields.rating - 1;
                        handleChange("rating", newValue);
                      }}
                    />
                  ),
                  readOnly: true,
                }}
                id="rating"
                label="Rating"
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="formitem5">
              <Autocomplete
                value={fields.clientRevenue || null}
                style={{ width: "48%" }}
                options={clientRevenueDataOption}
                getOptionLabel={(option) => option}
                defaultValue={clientRevenueDataOption[7]}
                onChange={(_, newValue) => {
                  handleChange("clientRevenue", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={"Client Revenue"}
                    placeholder="Client Revenue"
                  />
                )}
                required
              />
              <Autocomplete
                value={fields.managerName || ""}
                style={{ width: "48%" }}
                options={accManagers.map((item) => item.name).sort()}
                getOptionLabel={(option) => option}
                defaultValue={null}
                onChange={(_, newValue) => {
                  handleChange("managerName", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={"Accounts Manager"}
                    placeholder="Accounts Manager"
                  />
                )}
              />
            </div>
            <div className="formitem2">
              {" "}
              <div style={{ width: "30%" }}>
                <DatePicker
                  variant="outlined"
                  error={errors}
                  date={fields.startdate}
                  setdate={setFields}
                  field={"startdate"}
                  title={"Contract Start Date"}
                />
                <div style={{ color: "red" }}>{errors["startdate"]}</div>
              </div>
              <div style={{ width: "30%" }}>
                <DatePicker
                  error={errors}
                  minDate={fields.startdate}
                  date={fields.enddate}
                  setdate={setFields}
                  field={"enddate"}
                  title={"Contract End Date"}
                />
                <div style={{ color: "red" }}>{errors["enddate"]}</div>
              </div>
              <div style={{ width: "30%" }}>
                <DatePicker
                  error={errors}
                  date={fields.insurancedate}
                  setdate={setFields}
                  field={"insurancedate"}
                  title={"Insurance Date"}
                />
                <div style={{ color: "red" }}>{errors["insurancedate"]}</div>
              </div>
            </div>
            <div className="formitem3">
              <TextField
                variant="outlined"
                id="name"
                label="Comments"
                style={{ width: "100%" }}
                type="text"
                value={
                  fields.comments === " "
                    ? fields?.comments?.trim()
                    : fields?.comments || ""
                }
                onChange={(e) => {
                  handleChange("comments", e);
                }}
                onBlur={(e) => {
                  handleChange("comments", e);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
              />
            </div>
            <div className="formitem4">
              {" "}
              <p> Is Active</p>{" "}
              <Checkbox
                style={{ marginTop: "0.2rem", marginLeft: "0.9rem" }}
                checked={fields.status ? fields.status : 0}
                onChange={(_, newVal) => {
                  if (newVal) {
                    handleChange("status", 1);
                  } else {
                    handleChange("status", 0);
                  }
                }}
                inputProps={{ "aria-label": "controlled" }}
                isRequired
              />
            </div>
            <div className="formitem">
              <Button
                color="primary"
                style={{ width: "100%" }}
                variant="contained"
                disabled={loading || formProcessing}
                onClick={(events) => {
                  handlecomplete(events);
                }}
                id="updateCustomer"
              >
                {(loading || formProcessing) && (
                  <span style={{ color: "white" }} id="custSpan">
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Please Wait...
                  </span>
                )}
                {!(loading || formProcessing) && (
                  <span>
                    {localStorage.getItem("EditRowData") ? "Update" : "Submit"}
                  </span>
                )}
              </Button>
            </div>
          </form>
          <div class="documentsection">
            {localStorage.getItem("EditRowData") ? (
              <UserDocumentLabel
                setFields={setFields}
                resumeorDoc="Document"
                labelName={"UserCategory"}
                id={fields.id || null}
              />
            ) : (
              ""
            )}
          </div>
        </Grid>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          disableEscapeKeyDown={true}
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div style={{ width: "300px" }}>
              <DialogContentText id="alert-dialog-description">
                {"Success!!"}
              </DialogContentText>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};
AddEditCustomerInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(
  AddEditCustomerInner
);
function AddEditCustomer() {
  return <CompWithStyleInner />;
}
export default AddEditCustomer;

import gql from 'graphql-tag';
export const getAllTestInfo = `
    query  {allTests{
      Tests{
        code
        description
        duration
        id
        isactive
        testquestionmaps_ibfk_1{
          Testquestionmaps{
            complexity
            numberofquestions
            skill
            testid
            type
            id
          }
        }
      }
    }}`;

export const getAllVendor = `
    query {
      allVendors{
                        Vendors{
                          id
                          name
                          email
                          address
                          contactnumber
                          contactperson
                          numberofemployees
                          experties
                          othercontacts
                          active
                          rating
                          createdon
                        }
                      }
                      
              }`;

export const getAllJobs = `
              query {
                allJobdescriptions{
                Jobdescriptions {
                  id
                  title
                  description
                  internalnotes
                  technologies
                  screeningtesturl
                  active
                }
              }
  }`

export const getAllCustomers = `
  query($isActive:Int,$clientrevenue:String,$pageSize:Int,$pageNumber:Int){
    getAllCustomers(isActive:$isActive,clientrevenue:$clientrevenue,pageSize:$pageSize,pageNumber:$pageNumber){
      id
      name
      clientRevenue
      status
      startdate
      rating
      enddate
      insuranceDate
      manager
      msw
      noc
      nda
      profilestatus
      totalcount
      comments
    }
}`;

export const getAllResignedUser = `{getUser(pageNumber:Int,pageSize:Int,checkStatus:boolean){
  id
            name
            projectTitle
            type
            resignation
            lastDate
            notes
}}`;

export const getAllCollages = `
query {
  allCollages{
  Collages {
    id
    emailid
    address
    mobilenumber
    active
    collagename
    contactperson
    location
    otherdetails
    phonenumber
    website
    rating
  }
}
}`;

export const getUserAvailabityTimes = `query ($where: AvailablefilterType,$showAllUser:Int, $showContractorUser:Int) {
    getUserAvailabityTime(where: $where , order: "id",showAllUser:$showAllUser, showContractorUser:$showContractorUser) {
        id
        designationName
        name
        designationId
        primarySkillRating
        secondarySkillRating
        secondarySkill2Rating
        allocation
        probation
        userAvailableLatestDate
        joiningDate
        primarySkillName
        secondarySkillName
        otherSkillName
        secondarySkill2Name
        totalExp
        expStartDate
        totalExpNew
        hourlyCost
        currProject
        resignation
        docPath
        docType
        notes
      }}`;

// checkpoint
export const getAllActiveUsers = `query($chars: String,$pageNumber:Int,$pageSize:Int,$isActive:Int,$managerId:Int){
  getAllActiveUser(chars:$chars,pageNumber:$pageNumber,pageSize:$pageSize,isActive:$isActive,managerId:$managerId){      
    id
    name
 }
}
`;

export const getAllActiveUser = `query($chars: String,$pageNumber:Int,$pageSize:Int,$isActive:Int,$managerId:Int){
    getAllActiveUser(chars:$chars,pageNumber:$pageNumber,pageSize:$pageSize,isActive:$isActive,managerId:$managerId){      
      id
      totalcount
      name
      managers
      type
      projectTitle
      joiningDate
      notes
      probation
   }
}
`;

export const getAllUserProjectsBasedOnCondition = `query($isActive: Boolean,$projectTitle:String,$pageSize:Int,$pageNumber:Int,$managerId:Int,$projectType:String){
  getAllUserProjectsBasedOnCondition(isActive: $isActive,projectTitle:$projectTitle,pageSize:$pageSize,pageNumber:$pageNumber,managerId:$managerId,projectType:$projectType){      
    projectTitle
    customer{
      id
    }
    isActive
    projectUser{
      id
      name
    }
  }
 }
`;
export const getAllDoctTypes = `
      {
        getAllDocType{
          docType
          id
        }
      }
      
      `;

export const getResumeResourceData = `
query getDocs($id:Int){
  queryUserAllFiles(id:$id){
    id
    userId
    docName
    docType
    docPath
    createdAt
    updatedAt
    docTypeId
  }
}
       `;

export const DownloadFile = `
  mutation downloadFiles($docId:Int,$id:Int,$path:String,$adminId:Int,$flag:String){
    downloadUserFile(docId:$docId,id:$id,path:$path,adminId:$adminId,flag:$flag){
      fileType
      fileData
      fileName
  }
}

`;

export const DownloadResumeResource = `
mutation downloadResumeResource($id:Int,$path:String){
  downloadResumeResource(id:$id,path:$path){
    fileType
    fileData
    fileName
  }
}

`;
export const editingProbationUser = gql`
mutation($notes:String,$probation:Int,$id:Int){
     updateProbation(notes:$notes,probation:$probation,id:$id)
   }`;

export const editingResignationUser = gql`
mutation($notes:String,$id:Int){
     updateResignation(notes:$notes,id:$id)
   }`;

export const editingUserNotes = gql`
mutation UserdetailsUpsertWithWhere($where: JSON, $data: JSON){
  UserdetailsUpsertWithWhere(where: $where, data: $data){
    id
  }
}`;

export const getUserInfoById = `
query GeTInfo($id:Int){userInfoOnEdit(id:$id){
  name
  bankAcNo
  contact
  email
  designationId
  reportingManager
  status
  permanentAddress
  currentAddress
  pan 
  aadhar
  path
  alternativeEmail
  joiningDate
  lastDate
  resignation
  BankName
  certification
  ifsc_code
  gender
  anniversary_Date
  highestEducation
  maritalStatus
  fathersName
  dob
  firstName
  lastName
  bloodgrp
  empcode
  totalExp
  fingerprintId
  techExp
  CL
  SL
  EL
  startingLeaveCount
  primarySkillRating
  primarySkillId
  secondarySkillRating
  secondarySkillId
  secondarySkill2Rating
  secondarySkill2Id
  otherSkills
  hourlyCost
  factor
  about
  loveAboutMyJob
  expStartDate
  recruiterName
  vendorId
  freelancer
  probation
  monitoring
  monitoringDate
  kra
}
}
`;


export const allSkills = `query {
  allSkills {
  Skills {
    id
    name
  }
  }
}`;

export const getTestDetails = `query ($where: JSON) {
  allTests(where: $where) {
    Tests {
      id
      code
    }
    }}`;

export const getQuestionDetails = `query ($where: JSON) {
  allQuestions(where: $where) {
    Questions {
      id
      question
    }
}}`;

// Mutations

export const addVendor = `mutation (
  $active: Float
  $address: String
  $contactnumber: String
  $contactperson: String
  $createdon: Date!
  $email: String
  $experties: String
  $name: String
  $numberofemployees: Float
  $rating: Float
  $othercontacts: String
) {
  saveVendors(
    obj: {
      active: $active
      address: $address
      contactnumber: $contactnumber
      contactperson: $contactperson
      createdon: $createdon
      email: $email
      experties: $experties
      name: $name
      numberofemployees: $numberofemployees
      rating: $rating
      othercontacts: $othercontacts
    }
  ) {
    active
    address
    contactnumber
    contactperson
    createdon
    email
    experties
    name
    numberofemployees
    rating
    othercontacts
  }
}`;

export const addCollege = `mutation(
  $active: Float,
  $address: String,
  $collagename: String,
  $contactperson: String,
  $createdby:Float,
  $createdon: Date!,
  $emailid: String,
  $location: String,
  $mobilenumber: String,
  $otherdetails: String,
  $phonenumber: String,
  $rating: Float,  
  $updatedby: Float,
  $updatedon: Date!,
  $website: String) {
        saveCollages(obj: {
  active:$active,
  address:$address,
  collagename:$collagename,
  contactperson:$contactperson,
  createdon:$createdon,
  createdby:$createdby,        
  emailid:$emailid,
  location: $location,
  mobilenumber: $mobilenumber,
  otherdetails: $otherdetails,
  phonenumber: $phonenumber,
  rating: $rating,         
  updatedby:$updatedby,
  updatedon:$updatedon,        
  website:$website,
  status: $status,
        }) {
          active
  address
  collagename
  contactperson
  createdon
  createdby
  emailid
  location
  mobilenumber
  otherdetails
  phonenumber
  rating
  updatedby
  updatedon    
  website
    }
  }
`;

export const addJobs = `mutation(
$active: Float,
$createdby:Float,
$createdon: Date!,
$title: String,
$description: String,
$internalnotes: String,
$technologies: String,
$screeningtesturl: String,
$updatedby: Float,
$updatedon: Date!) {
      saveJobdescription(obj: {
active:$active,
createdby:$createdby,
createdon:$createdon,
title:$title,
description:$description,
internalnotes:$internalnotes,
technologies:$technologies,
screeningtesturl:$screeningtesturl,
updatedby:$updatedby,
updatedon:$updatedon,        
      }) {
active
createdby
createdon
title
description
internalnotes
technologies
screeningtesturl
updatedby
updatedon   
        }
}
`;

///////////////////////////////
export const getAllProjects = `query {
 getAllProjects{
  projectTitle
  customer{
    name
  }
  manager{
    name
  }
  startDate
  endDate
  tokenStatus
  }
}
}`;

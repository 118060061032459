import React from 'react';
import LoginForm from "./forms/LoginForm";
import { ApolloConsumer } from 'react-apollo';


const Login = (props) => {
    let auth = props.auth;
    return (
        <ApolloConsumer>{
            client => {
                return <LoginForm  client={client} auth={auth} checkToken={props.checkToken} />
            }
        }
        </ApolloConsumer>
    );
}


export default Login;
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { gql, useMutation } from "@apollo/client";
import { useHistory,Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import "../All.css";
import { authHeader } from "../config";
import { IsValidEmail, isValidPhoneNumber } from "../utils/Validation";

const styles = makeStyles(() => ({}));

const AddEditCollegeInner = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formProcessing, setFormProcessing] = useState(false);

  let router = useHistory();

  const handleDialogClose = () => {
    setDialogOpen(false);
    router.push({ pathname: "/college" });
    setFormProcessing(false);
  };
  function submitCompleted() {
    setFormProcessing(true);
    setDialogOpen(true);
  }

  const gqlMutation = (function () {
    let code = `mutation(
          $id: Float,
        $active: Float,
        $address: String,
        $collagename: String,
        $contactperson: String,
        $createdby:Float,
        $createdon: Date!,
        $emailid: String,
        $location: String,
        $mobilenumber: String,
        $otherdetails: String,
        $phonenumber: String,
        $rating: Float,  
        $updatedby: Float,
        $updatedon: Date!,
        $website: String) {
              saveCollages(obj: {
        id: $id,          
        active:$active,
        address:$address,
        collagename:$collagename,
        contactperson:$contactperson,
        createdon:$createdon,
        createdby:$createdby,        
        emailid:$emailid,
        location: $location,
        mobilenumber: $mobilenumber,
        otherdetails: $otherdetails,
        phonenumber: $phonenumber,
        rating: $rating,         
        updatedby:$updatedby,
        updatedon:$updatedon,        
        website:$website
              }) {
        id
        active
        address
        collagename
        contactperson
        createdon
        createdby
        emailid
        location
        mobilenumber
        otherdetails
        phonenumber
        rating
        updatedby
        updatedon    
        website
          }
        }`;
    return gql`
      ${code}
    `;
  })();

  const [saveMutation, {loading, error: submitError }] =
    useMutation(gqlMutation, {
      variables: {
        active: fields.active,
        address: fields.address,
        collagename: fields.collagename,
        contactperson: fields.contactperson,
        createdby: JSON.parse(localStorage.getItem("id")),
        createdon: new Date(),
        location: fields.location,
        emailid: fields.emailid,
        mobilenumber: fields.mobilenumber,
        otherdetails: fields.otherdetails,
        phonenumber: fields.phonenumber,
        rating: parseInt(fields.rating),
        updatedby: JSON.parse(localStorage.getItem("id")),
        updatedon: new Date(),
        website: fields.website,
        id: fields.id,
      },
      onCompleted: submitCompleted,
      context: authHeader(),
    });
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("EditRowData"));

    if (data) {
      Object.entries(data).forEach(() => {
        setFields({
          active: data.active,
          address: data.address,
          collagename: data.collagename,
          contactperson: data.contactperson,
          createdby: JSON.parse(localStorage.getItem("id")),
          location: data.location,
          emailid: data.emailid,
          mobilenumber: data.mobilenumber,
          otherdetails: data.otherdetails,
          phonenumber: data.phonenumber,
          rating: data.rating,
          updatedby: JSON.parse(localStorage.getItem("id")),
          website: data.website,
          id: data.id,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (submitError) {
      setFormError(submitError.toString());
    }
  }, [submitError]);

  useEffect(() => {
    localStorage.removeItem("EditRowData");
  }, []);


  const makePost = () => {
    let fieldsT = {};
    let data = JSON.parse(localStorage.getItem("EditRowData"));
    if (data) {
      Object.assign(fieldsT, fields);
      saveMutation({
        variables: {
          active: fieldsT.active,
          address: fieldsT.address,
          collagename: fieldsT.collagename,
          contactperson: fieldsT.contactperson,
          createdby: JSON.parse(localStorage.getItem("id")),
          createdon: new Date(),
          location: fieldsT.location,
          emailid: fieldsT.emailid,
          mobilenumber: fieldsT.mobilenumber,
          otherdetails: fieldsT.otherdetails,
          phonenumber: fieldsT.phonenumber,
          rating: parseInt(fields.rating),
          updatedby: JSON.parse(localStorage.getItem("id")),
          updatedon: new Date(),
          website: fieldsT.website,
          id: fieldsT.id,
        },
      });
    } else
      saveMutation({
        variables: {
          input: fieldsT,
        },
      });
  };

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e && e.target ? e.target.value : e;
    let updatedValue = fieldValue;
    if (fieldname === undefined || fieldname === "collagename") {
      if (fieldname === undefined) {
        fieldValue = fields["collagename"] ? fields["collagename"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["collagename"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid = false;
        errorsT["collagename"] = "Collage Name is required";
      }
    }

    if (fieldname === undefined || fieldname === "emailid") {
      if (fieldname === undefined) {
        fieldValue = fields["emailid"] ? fields["emailid"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["emailid"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid = false;
        errorsT["emailid"] = "Email is required";
      } else if (!IsValidEmail(updatedValue)) {
        formIsValid = false;
        errorsT["emailid"] = "Please enter Email in correct format";
      }
    }

    //phonenumber validations
    if (fieldname === undefined || fieldname === "phonenumber") {
      if (fieldname === undefined) {
        fieldValue = fields["phonenumber"] ? fields["phonenumber"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["phonenumber"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid = false;
        errorsT["phonenumber"] = "Phone Number is required";
      } else if (!isValidPhoneNumber(updatedValue)) {
        formIsValid = false;
        errorsT["phonenumber"] = "Please enter Phone in correct format";
      }
    }

    if (fieldname === undefined || fieldname === "contactperson") {
      if (fieldname === undefined) {
        fieldValue = fields["contactperson"] ? fields["contactperson"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["contactperson"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid = false;
        errorsT["contactperson"] = "Contact Person is required";
      }
    }

    if (fieldname === undefined || fieldname === "location") {
      if (fieldname === undefined) {
        fieldValue = fields["location"] ? fields["location"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["location"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid = false;
        errorsT["location"] = "Location is required";
      }
    }

    setErrors({ ...errorsT });
    return { formIsValid, updatedValue };
  };

  const handleChange = (field, e) => {
    const { updatedValue } = handleValidation(field, e);
    let fieldsT = fields;
    fieldsT[field] = updatedValue;
    setFields({ ...fieldsT });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { formIsValid} = handleValidation();
    if (formIsValid) {
      makePost();
    }
  };
  return (
    <>
      <Grid container xs={12} md={12} spacing={3}>
        <div className="inner-header">
          <div className="margin-auto" style={{ margin: "0px 38% auto" }}>
            <h4 className="page-title">
              {JSON.parse(localStorage.getItem("EditRowData")) ? "Edit" : "Add"}{" "}
              College
            </h4>
          </div>
          <div>
            <Link className="navNames" to="/college">
              <Button color="primary" variant="outlined">
                {" "}
                Back{" "}
              </Button>
            </Link>
          </div>
        </div>
      </Grid>
      <form>
        <div style={{ margin: "4%" }}>
          <Grid container spacing={4}>
            <Grid container item xs={6} direction="column">
              <TextField
                variant="outlined"
                id="collagename"
                label="Collage Name"
                type="text"
                value={
                  fields.collagename == " "
                    ? fields?.collagename?.trim()
                    : fields?.collagename || ""
                }
                onChange={(e) => {
                  handleChange("collagename", e);
                }}
                onBlur={(e) => {
                  handleChange("collagename", e);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                required
              />
              <div style={{ color: "red" }}>{errors["collagename"]}</div>
            </Grid>
            <Grid container item xs={6} direction="column">
              <TextField
                variant="outlined"
                id="location"
                label="Location"
                type="text"
                value={
                  fields?.location == " "
                    ? fields?.location?.trim()
                    : fields?.location || ""
                }
                onChange={(e) => {
                  handleChange("location", e);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                required
              />
              <div style={{ color: "red" }}>{errors["location"]}</div>
            </Grid>
            <Grid container item xs={6} direction="column">
              <TextField
                variant="outlined"
                id="address"
                label="Address"
                type="text"
                value={
                  fields?.address == " "
                    ? fields?.address?.trim()
                    : fields?.address || ""
                }
                onChange={(event) => {
                  handleChange("address", event);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                // required
              />
              {/* <div style={{color: "red"}}>{errors["address"]}</div> */}
            </Grid>
            <Grid container item xs={6} direction="column">
              <TextField
                variant="outlined"
                id="website"
                label="Website"
                type="text"
                value={fields.website ? fields.website : ""}
                onChange={(e) => {
                  handleChange("website", e);
                }}
                onBlur={(e) => {
                  handleChange("website", e);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                // required
              />
              {/* <div style={{color: "red"}}>{errors["website"]}</div> */}
            </Grid>
            <Grid container item xs={6} direction="column">
              <TextField
                variant="outlined"
                id="contactperson"
                label="Contact Person"
                type="text"
                value={
                  fields?.contactperson == " "
                    ? fields?.contactperson?.trim()
                    : fields?.contactperson || ""
                }
                onChange={(e) => {
                  handleChange("contactperson", e);
                }}
                onBlur={(e) => {
                  handleChange("contactperson", e);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                required
              />
              <div style={{ color: "red" }}>{errors["contactperson"]}</div>
            </Grid>

            <Grid container item xs={6} direction="column">
              <TextField
                variant="outlined"
                id="emailid"
                label="Email"
                type="text"
                value={fields.emailid || ""}
                onChange={(e) => {
                  handleChange("emailid", e);
                }}
                onBlur={(e) => {
                  handleChange("emailid", e);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                required
              />
              <div style={{ color: "red" }}>{errors["emailid"]}</div>
            </Grid>

            <Grid container item xs={6} direction="column">
              <TextField
                variant="outlined"
                id="phonenumber"
                label="Phone Number"
                type="text"
                value={fields.phonenumber || ""}
                onChange={(e) => {
                  handleChange("phonenumber", e);
                }}
                onBlur={(e) => {
                  handleChange("phonenumber", e);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                required
              />
              <div style={{ color: "red" }}>{errors["phonenumber"]}</div>
            </Grid>

            <Grid container item xs={6} direction="column">
              <TextField
                variant="outlined"
                id="mobilenumber"
                label="Mobile Number"
                type="text"
                value={fields.mobilenumber || ""}
                onChange={(e) => {
                  handleChange("mobilenumber", e);
                }}
                onBlur={(e) => {
                  handleChange("mobilenumber", e);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                // required
              />
              {/* <div style={{color: "red"}}>{errors["mobilenumber"]}</div> */}
            </Grid>
            <Grid container item xs={6} direction="column">
              <TextField
                variant="outlined"
                id="otherdetails"
                label="Other Details"
                type="text"
                value={
                  fields?.otherdetails == " "
                    ? fields?.otherdetails?.trim()
                    : fields?.otherdetails || ""
                }
                onChange={(e) => {
                  handleChange("otherdetails", e);
                }}
                onBlur={(e) => {
                  handleChange("otherdetails", e);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                // required
              />
            </Grid>

            <Grid container item xs={6} direction="column">
              <TextField
                variant="outlined"
                id="rating"
                label="Rating"
                type="text"
                value={fields.rating || ""}
                onChange={(e) => {
                  handleChange("rating", e);
                }}
                onBlur={(e) => {
                  handleChange("rating", e);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
                // required
              />
            </Grid>

            <Grid item xs={12}>
              <div style={{ color: "red" }}>{formError}</div>
              <Button
                color="primary"
                variant="contained"
                disabled={loading || formProcessing}
                onClick={(e) => {
                  handleSubmit(e);
                }}
                id="update"
              >
                {(loading || formProcessing) && (
                  <span style={{ color: "white" }}>
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Please Wait...
                  </span>
                )}
                {!(loading || formProcessing) && (
                  <span>
                    {localStorage.getItem("EditRowData") ? "Update" : "Submit"}
                  </span>
                )}
              </Button>
              <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                disableEscapeKeyDown={true}
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <div style={{ width: "300px" }}>
                    <DialogContentText id="alert-dialog-description">
                      {"Success!!"}
                    </DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogClose} color="primary" autoFocus>
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
};
AddEditCollegeInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(
  AddEditCollegeInner
);
function AddEditCollege() {
  return <CompWithStyleInner />;
}
export default AddEditCollege;
